import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, computed, inject } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { Asset, DamHeaderComponent, GridComponent, ListComponent } from 'addiction-components';
import { LottieModule } from 'ngx-lottie';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { environment } from 'src/environments/environment';
import { CollectionSelectorDetailService } from './collection-selector-detail.service';
import { ToFormatPipe } from 'src/app/shared/pipes/to-format.pipe';
import { ToIconPipe } from 'src/app/shared/pipes/to-icon.pipe';

@Component({
	selector: 'datalean-collection-selector-detail',
	standalone: true,
	imports: [CommonModule, GridComponent, ListComponent, TranslateModule, LottieModule, MatCheckboxModule, DamHeaderComponent, ToFormatPipe, ToIconPipe],
	providers: [CollectionSelectorDetailService],
	templateUrl: './collection-selector-detail.component.html',
	styleUrls: ['./collection-selector-detail.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionSelectorDetailComponent implements OnInit {
	readonly STATE_STATUS = STATE_STATUS;
	readonly sortOptions = environment.sortOptions;
	showFilterMenu = false;
	showNavigationTree = false;
	viewMode: 'grid' | 'list' = 'grid';
	sliderValue = 4;
	pageSize = environment.pageSize;
	selectionMode = 'single';
	public service = inject(CollectionSelectorDetailService);
	gridData = computed(() => {
		const assets = this.service.filteredCollectionAssets();
		if (assets) {
			return {
				items: [assets ?? []],
				pages: [0],
				totalItems: (assets ?? []).length,
			};
		}
		return {
			items: [[]],
			pages: [0],
			totalItems: -1,
		};
	});

	@Input({ required: true }) collectionUUID!: string;
	@Output() selectedAsset = new EventEmitter<Asset>();
	@Output() back = new EventEmitter<void>();

	ngOnInit(): void {
		this.service.setCollectionUUID(this.collectionUUID);
	}

	onCardClick(event: Asset) {
		if (this.selectionMode === 'single') {
			this.service.toggleSelectedElement(event.uuid);
			if (this.service.selectedElements().has(event.uuid)) {
				this.selectedAsset.emit(event);
			} else {
				this.selectedAsset.emit(undefined);
			}
		}
		// if (this.selectionMode === 'multi') {
		// 	this.service.toggleSelectedElements(event.uuid);
		// 	this.selectionChange.emit(Array.from(this.service.selectedElements()));
		// }
	}
}
