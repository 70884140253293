<addiction-dam-header
	[showNavigationButton]="true"
	[showFilterButton]="true"
	[showSearchField]="true"
	[showSizeSlider]="viewMode === 'grid'"
	[showViewModeButtons]="true"
	[showSortField]="true"
	[sortOptions]="sortOptions"
	[showCollectionButton]="true"
	[showAddButton]="dialogRef && selectionMode !== 'off'"
	[(viewMode)]="viewMode"
	[(sliderValue)]="sliderValue"
	(filterButtonClick)="showFilterMenu = !showFilterMenu"
	(search)="service.setSearchValue($event)"
	(sortChange)="service.setSortOption($event)"
	[filterButtonClass]="{ active: showFilterMenu }"
	[navigationButtonClass]="{ active: showNavigationTree }"
	(collectionButtonClick)="collectionClick.emit()"
	(navigationButtonClick)="showNavigationTree = !showNavigationTree"
	(addButtonClick)="showAddModal = true"></addiction-dam-header>

<div class="grid-container">
	<div class="tree-browser-container" *ngIf="showNavigationTree">
		<addiction-tree-browser
			*ngIf="service.features() as nodes"
			[nodes]="nodes"
			[config]="treeConfig"
			[selectable]="true"
			(selected)="service.updateActiveFilters({ featureValueList: $event.uuid })"
			(selectedPathChange)="selectedFeaturePath = $event">
		</addiction-tree-browser>
	</div>

	<div class="filter-menu-container" *ngIf="showFilterMenu">
		<addiction-filter-menu
			*ngIf="service.filterMenuSections() as filterMenuSection"
			[filterMenuSection]="filterMenuSection"
			[showFilterDelete]="(showFilterDelete$ | async) ?? false"
			(filterDelete)="reset()"
			(filterChanged)="onFilterChanged($event)"></addiction-filter-menu>
	</div>

	<div #dndContainer="appDnd" appDnd (fileDropped)="manageFilesDropped($event)">
		<addiction-list
			[pageSize]="pageSize"
			[data]="service.gridData()"
			*ngIf="viewMode === 'list'"
			[loadingTemplate]="listLoadingTemplate"
			[isLoading]="service.status() === STATE_STATUS.LOADING"
			[rowTemplate]="rowTemplate"
			[tableHeaderTemplate]="tableHeaderTemplate"
			[noItemsTemplate]="noItemsTemplate"
			(rowClick)="onCardClick($event)"></addiction-list>
		<addiction-grid
			[pageSize]="pageSize"
			[columns]="10 - sliderValue"
			[data]="service.gridData()"
			*ngIf="viewMode === 'grid'"
			[cardTemplate]="cardTemplate"
			(pageChanges)="service.setPages($event)"
			[noItemsTemplate]="noItemsTemplate"
			(cardClick)="onCardClick($event)"></addiction-grid>

		<div *ngIf="dndContainer.fileOver" class="dnd-message">
			<ng-lottie [options]="{ path: './assets/lottie/icn-dam-dragdrop.json' }"></ng-lottie>
			<span>{{ 'GRID.DROP_FILES_HERE' | translate }}</span>
		</div>
	</div>

	<!-- TODO: rimuovere in favore di una modale vera-->
	<div class="ngxdropzone-container viewmode-dialog" *ngIf="showAddModal">
		<div class="modal-input-container">
			<div class="header-container">
				<div class="title">
					{{ 'MEDIA_LIBRARY.MODAL_DROPZONE.TITLE' | translate }}
				</div>
				<div class="close-container">
					<button class="close" (click)="showAddModal = false"></button>
				</div>
			</div>
			<div class="body-container">
				<!--         TODO - Al div sotto manca: [ngClass]="{'loading' : uploading$ | async}"-->
				<div #dndContainer="appDnd" appDnd (fileDropped)="manageFilesDropped($event)" class="dnd-container">
					<input #fileInput type="file" hidden [accept]="getAccepted()" (change)="manageFilesDropped($event)" />
					<div class="dnd-message">
						<ng-lottie [options]="{ path: './assets/lottie/icn-dam-dragdrop.json' }"></ng-lottie>
						<span class="text">{{ 'MEDIA_LIBRARY.DROPZONE_TITLE' | translate }}</span>
					</div>
					<div class="fake-input-file">
						<button class="select" (click)="fileInput.click()">
							<span>{{ 'MEDIA_LIBRARY.SELECT' | translate }}</span>
						</button>
					</div>
					<!-- TODO - Da implementare come per media-library-asset-editor -->
					<!--          <div class="upload-progress" *ngIf="uploading$ | async">-->
					<!-- TODO STYLE - Cambiare il lottie -->
					<!--            <ng-lottie [options]="{ path: './assets/lottie/icn-dam-nocontent.json' }"></ng-lottie>-->
					<!--          </div>-->
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #tableHeaderTemplate>
	<div class="single-row-header">
		<div class="single-column checkbox">
			<span class="checkbox"></span>
		</div>
		<div class="single-column image">
			<span class="image"></span>
		</div>
		<div class="single-column double title">
			<span class="highlight">{{ 'LIST.NAME' | translate }}</span>
		</div>
		<div class="single-column single format">
			<span class="format">{{ 'LIST.FORMAT' | translate }}</span>
		</div>
		<div class="single-column double tag">
			<span>{{ 'LIST.TAG' | translate }}</span>
		</div>
		<div class="single-column single created">
			<span>{{ 'LIST.CREATED' | translate }}</span>
		</div>
		<div class="single-column single modified">
			<span>{{ 'LIST.MODIFIED' | translate }}</span>
		</div>
		<div class="single-column arrow">
			<span class="arrow"></span>
		</div>
	</div>
</ng-template>

<ng-template #cardTemplate let-item="item">
	<!-- [ngClass]="{ selected: service.selectedElements().has(item.uuid) }" -->
	<div class="card" *ngIf="item && service.status() !== STATE_STATUS.LOADING">
		<div class="image-container">
			<img [src]="item.preview?.url ?? (item.format | toIcon)" />
			<mat-checkbox
				class="checkbox"
				[class.mat-checkbox-disabled]="false"
				[disabled]="true"
				[checked]="service.selectedElements().has(item.uuid)"
				(click)="$event.stopPropagation()">
			</mat-checkbox>
			<span class="format" *ngIf="item.format">{{ item.format | toFormat }}</span>
		</div>
		<div class="description-container">
			<span class="name">{{ item.name }}</span>
		</div>
	</div>
	<div class="card loading" *ngIf="!item || service.status() === STATE_STATUS.LOADING">
		<div class="image-container"></div>
		<div class="description-container">
			<span class="name"></span>
		</div>
	</div>
</ng-template>

<ng-template #rowTemplate let-item="item">
	<div class="single-row" *ngIf="item && service.status() !== STATE_STATUS.LOADING">
		<mat-checkbox
			class="checkbox"
			[class.mat-checkbox-disabled]="false"
			[disabled]="true"
			[checked]="service.selectedElements().has(item.uuid)"
			(click)="$event.stopPropagation()">
		</mat-checkbox>
		<div class="single-column image">
			<img [src]="item.preview?.url ?? (item.format | toIcon)" />
		</div>
		<div class="single-column double title">
			<span class="highlight">{{ item.name }}</span>
		</div>
		<div class="single-column single format">
			<span class="format" *ngIf="item.format">{{ item.format | toFormat }}</span>
		</div>
		<div class="single-column double tag">
			<span class="tag">
				<ng-container *ngFor="let tag of item.tags; let last = last">
					<span class="single-tag">{{ tag.name }}</span>
					<ng-container *ngIf="!last">, </ng-container>
				</ng-container>
			</span>
		</div>
		<div class="single-column single created">
			<span>{{ item.createDate | date : 'dd/MM/yyyy @ HH:mm' }}</span>
		</div>
		<div class="single-column single modified">
			<span>{{ item.updateDate | date : 'dd/MM/yyyy @ HH:mm' }}</span>
		</div>
		<div class="single-column arrow">
			<span class="arrow"></span>
		</div>
	</div>
</ng-template>

<ng-template #noItemsTemplate>
	<div class="no-results-container bigger" *ngIf="service.status() !== STATE_STATUS.LOADING">
		<ng-lottie [options]="{ path: './assets/lottie/icn-dam-nocontent.json' }"></ng-lottie>
		<div class="title">
			{{ 'GRID.NO_RESULTS_TITLE' | translate }}
		</div>
		<div class="text">
			{{ 'GRID.NO_RESULTS_TEXT' | translate }}
		</div>
	</div>
</ng-template>

<ng-template #listLoadingTemplate>
	<div class="single-row-skeleton" *ngFor="let item of [].constructor(10)">
		<div class="single-column checkbox">
			<span class="checkbox"></span>
		</div>
		<div class="single-column image">
		</div>
		<div class="single-column double title">
			<span></span>
		</div>
		<div class="single-column single">
			<span></span>
		</div>
		<div class="single-column double ">
			<span ></span>
		</div>
		<div class="single-column single ">
			<span></span>
		</div>
		<div class="single-column single">
			<span></span>
		</div>
		<div class="single-column options">
			<div class="button"></div>
		</div>
	</div>
</ng-template>
