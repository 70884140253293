import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
	ActiveModalService,
	CheckboxComponent,
	DndDirective,
	ModalService,
	ModalTargetDirective,
	SearchComponent,
	ToLocalizedValuePipe,
} from 'addiction-components';
import { ClipboardModule } from 'ngx-clipboard';
import { LottieModule } from 'ngx-lottie';
import { ToIconPipe } from './pipes/to-icon.pipe';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		NgxDatatableModule,
		MatDialogModule,
		MatMenuModule,
		MatIconModule,
		MatTabsModule,
		MatCheckboxModule,
		DragDropModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		TranslateModule,
		ClipboardModule,
		LetDirective,
		SearchComponent,
		DndDirective,
		ToLocalizedValuePipe,
		SearchComponent,
		ModalTargetDirective,
		ToIconPipe,
		LottieModule,
		CheckboxComponent,
	],
	exports: [
		CommonModule,
		NgxDatatableModule,
		FormsModule,
		ReactiveFormsModule,
		MatIconModule,
		DragDropModule,
		MatMenuModule,
		ToLocalizedValuePipe,
		TranslateModule,
		MatDialogModule,
		ToIconPipe,
		LetDirective,
		SearchComponent,
		DndDirective,
		ModalTargetDirective,
	],
	providers: [ToLocalizedValuePipe, ModalService, ActiveModalService],
})
export class SharedModule {}
