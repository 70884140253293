import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, forwardRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { ChipComponent, CloseReason, DataleanBaseApiService, LimitType, MessageHandler, Parts } from 'addiction-components';
import { Observable, filter, first, map, of, switchMap } from 'rxjs';
import { CommunitySelectSelectors } from 'src/app/core/state/app.selectors';
import { ChipSelectorInput } from '../../models/chip-selector-input.interface';
import { DataleanDirectiveModule } from '../../modules/directive.module';
import { GenericSelectorDialogComponent } from '../generic-selector-dialog/generic-selector-dialog.component';
import { BaseChipsSelectorComponent, ChipValue } from './base-chips-selector.component';

// ℹ️ Segue la documentazione: https://addiction.atlassian.net/wiki/spaces/DOCTEC/pages/2269315073/ChipsSelector

@Component({
	selector: 'datalean-generic-chip-selector',
	templateUrl: './chips-selector.component.html',
	styleUrls: ['./chips-selector.component.scss'],
	standalone: true,
	imports: [CommonModule, ChipComponent, ReactiveFormsModule, MatTooltipModule, DataleanDirectiveModule],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => GenericChipSelectorComponent) },
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => GenericChipSelectorComponent),
		},
	],
})
export class GenericChipSelectorComponent extends BaseChipsSelectorComponent implements OnInit {
	protected lastCommunitySelectedUUID: string | undefined;

	protected dataleanService = inject(DataleanBaseApiService);
	protected modalSrv = inject(MessageHandler);
	protected store = inject(Store);

	@Input({ required: true }) inputData!: ChipSelectorInput;

	private destroyRef$ = inject(DestroyRef);

	constructor() {
		super();
		this.store
			.select(CommunitySelectSelectors.selectLastCommunitySelected)
			.pipe(takeUntilDestroyed())
			.subscribe((communitySelected) => (this.lastCommunitySelectedUUID = communitySelected));
	}

	ngOnInit(): void {
		this.items.pipe(takeUntilDestroyed(this.destroyRef$)).subscribe({
			next: (items) => {
				if (this.selectionLimit) {
					if (this.selectionLimit.type === LimitType.BLOCK && items.length === this.selectionLimit.value) {
						this.showAddButton = false;
					} else {
						if (!this.disabled && !this.readonly && !this.showAddButton) {
							this.showAddButton = true;
						}
					}
				}
			},
		});
	}

	override writeValue(val: (ChipValue | string)[] | null): void {
		// console.log('v', v);
		if (!val || !Array.isArray(val) || !val.length) return;
		const uuids = val.map((item) => (typeof item === 'string' ? item : item.uuid));
		// console.log('uuids', uuids);
		if (this.inputData?.entityType) {
			const params: { uuid: string[]; communityUUID?: string } = {
				uuid: uuids,
			};
			if (this.lastCommunitySelectedUUID && this.lastCommunitySelectedUUID !== 'all') {
				params['communityUUID'] = this.lastCommunitySelectedUUID;
			}
			this.skeletonLoaderChipsCounter.next(uuids.length);

			this.dataleanService
				.getManyPaged<Record<string, unknown>>(this.inputData?.endpoint, [Parts.EMPTY], {
					additionalParams: params,
				})
				.pipe(
					map((result) => result.result.map((item) => this.mapToChipValue(item))),
					first(),
				)
				.subscribe({
					next: (result) => {
						this.items.next(result);
						this.skeletonLoaderChipsCounter.next(0);
					},
					error: (err) => {
						this.skeletonLoaderChipsCounter.next(0);
						console.error('Error fetching data', err);
					},
				});
		}
	}

	override openDialog(currentValues: ChipValue[] = []): Observable<{ selected: ChipValue[]; replace?: boolean | undefined }> {
		const selected = (currentValues || []).map(({ uuid }) => uuid);

		return this.modalSrv
			.openDialog(
				GenericSelectorDialogComponent,
				{ title: this.inputData?.modalTitle },
				{
					negativeReflection: false,
					endpoint: this.inputData?.endpoint,
					selectedUUIDs: selected,
					additionalParams: this.inputData?.additionalParams ?? {},
					selectionLimit: this.selectionLimit,
					isRelatedEntities: this.inputData.isRelatedEntities,
				},
			)
			.result$.pipe(
				filter((result): result is { reason: CloseReason; data: { selected: string[] } } => result?.reason === 'COMPLETE' && !!result.data),
				switchMap(({ data }) => {
					if (!data?.selected?.length) return of();
					// console.log('data', data);
					if (this.inputData?.entityType) {
						const params: { uuid: string[]; communityUUID?: string } = {
							uuid: data?.selected,
						};
						if (this.lastCommunitySelectedUUID && this.lastCommunitySelectedUUID !== 'all') {
							params['communityUUID'] = this.lastCommunitySelectedUUID;
						}
						return this.dataleanService.getManyPaged<Record<string, unknown>>(this.inputData?.endpoint, [Parts.EMPTY], {
							additionalParams: params,
						});
					}
					return of();
				}),
				map((result) => ({ selected: result.result.map((item) => this.mapToChipValue(item)), replace: true })),
			);
	}

	private mapToChipValue(entity: Record<string, unknown>): ChipValue {
		return {
			uuid: `${entity['uuid']}`,
			name: `${entity['name']}`,
		};
	}
}
