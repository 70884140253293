<div>
	<ng-container *ngrxLet="tableRows$ as data">
		<datalean-table
			(pageChange)="onPageChanged($event)"
			[columns]="columns"
			[dataSource]="data"
			[pageSize]="pageSize"
			[templates]="[t1, t2]"
			[noItemsTemplate]="noItemsTemplate"
			[hidePaginatorIfNotNeeded]="true"
			[indeterminate]="gcs.selectAllIndeterminate"
			[selectAll]="gcs.selectAllChecked"
			(checkboxHeaderSelected)="onSelectionChange($event, 'all')">
			<ng-template #t1 let-item>
				<addiction-checkbox [value]="isItemChecked(item.uuid)" (valueChange)="onSelectionChange($event, item.uuid)"></addiction-checkbox>
			</ng-template>
			<ng-template #t2 let-item>
				<span class="highlight">{{ item.name }}</span>
			</ng-template>
		</datalean-table>
	</ng-container>

	<ng-template #noItemsTemplate>
		<div class="no-results-container">
			<ng-lottie [options]="{ path: './assets/lottie/icn-dam-nocontent.json' }"></ng-lottie>
			<div class="title">
				{{ 'GRID.NO_RESULTS_TITLE' | translate }}
			</div>
			<div class="text">
				{{ 'GRID.NO_RESULTS_TEXT' | translate }}
			</div>
		</div>
	</ng-template>
</div>

<div class="buttons-container">
	<button (click)="close()">
		<span>{{ 'ADD' | translate }}</span>
	</button>
</div>
