import { Injectable } from '@angular/core';
import { PaginationInfo, Parts, DataleanBaseApiService, Collection } from 'addiction-components';
import { environment } from 'src/environments/environment';
import { Observable, forkJoin } from 'rxjs';
import { FilterParams } from 'src/app/shared/models/filterParams';

@Injectable({ providedIn: 'root' })
export class CollectionsService {
	constructor(private dataleanApi: DataleanBaseApiService) {}

	fetchCollections(pages: number[], gridSearch?: string): Observable<{ result: Collection[] | null; paginationInfo?: PaginationInfo }[]> {
		const obs: Observable<{ result: Collection[] | null; paginationInfo?: PaginationInfo }>[] = [];
		for (const page of pages) {
			const pagination = new PaginationInfo(25, page);
			const params: FilterParams = {};
			if (gridSearch) {
				params.q = gridSearch;
				params.searchFields = 'name';
			}
			obs.push(
				this.dataleanApi.getEntitiesWithPaginationData<Collection>(
					environment.collectionsUrl,
					params,
					[Parts.EMPTY],
					undefined,
					pagination,
					undefined,
				),
			);
		}
		return forkJoin(obs);
	}

	createCollection(collectionName: string): Observable<Collection> {
		const collection: Partial<Collection> = {
			name: collectionName,
			isPublic: true, //su DAM true, su app false
		};
		return this.dataleanApi.createEntity(environment.collectionsUrl, collection, []);
	}

	addToCollections(addParams: AlbumUpdateBody) {
		const body: AlbumUpdateBody = {
			albumUUIDs: addParams.albumUUIDs,
		};

		if (addParams.entityUUIDs && addParams.entityUUIDsMode) {
			body.entityUUIDsMode = addParams.entityUUIDsMode;
			body.entityUUIDs = addParams.entityUUIDs;
		}

		if (addParams.entityFilter) {
			body.entityFilter = addParams.entityFilter;
		}

		return this.dataleanApi.createEntity(environment.collectionsUrl + 'add', body, []);
	}

	removeFromCollections(removeParams: AlbumUpdateBody) {
		const body: AlbumUpdateBody = {
			albumUUIDs: removeParams.albumUUIDs,
		};

		if (removeParams.entityUUIDs && removeParams.entityUUIDsMode) {
			body.entityUUIDsMode = removeParams.entityUUIDsMode;
			body.entityUUIDs = removeParams.entityUUIDs;
		}

		if (removeParams.entityFilter) {
			body.entityFilter = removeParams.entityFilter;
		}

		return this.dataleanApi.createEntity(environment.collectionsUrl + 'remove', removeParams, []);
	}
}

export interface AlbumUpdateBody {
	albumUUIDs: string[];
	entityUUIDs?: string[];
	entityUUIDsMode?: 'exclusive' | 'inclusive';
	entityFilter?: FilterParams;
}
