import { SpecialHttpHeaderParams } from './../../shared/models/special-header-params.enum';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Injectable()
export class NotFoundRedirectInterceptor implements HttpInterceptor {
  private navigationService = inject(NavigationService)
	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const hasRedirectHttpParam = req.headers.get(SpecialHttpHeaderParams.REDIRECT_IF_NOT_FOUND_HEADER_KEY) === 'true';
		return next.handle(req).pipe(
			tap((event) => {
				if (event.type === HttpEventType.Response && (event.status === 204) && hasRedirectHttpParam) {
					this.navigationService.goToNotFoundPage();
				}
			}),
			catchError((error) => {
				/**
					* Per il redirect consideriamo anche i casi di Method not allowed
					* Esempio: Link con uuid con formato errato
				 */
				if((error.status === 405 || error.status === 404) && hasRedirectHttpParam) {
					this.navigationService.goToNotFoundPage();
				}
				return throwError(() => error);
			}),
		);
	}
}
