import { inject, Injectable, Signal, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Sort } from '@angular/material/sort';
import { DataleanSelectOption, PaginationInfo, Parts, SimpleObject, Structure, StructureType, UserStructure } from 'addiction-components';
import { Observable, filter, forkJoin, map, shareReplay } from 'rxjs';
import { ApplicationUsersActions } from 'src/app/core/state/app.actions';
import { ImportExportDialogComponent } from 'src/app/shared/components/import-export-dialog/import-export-dialog.component';
import { ApplicationUser } from 'src/app/shared/models';
import { BaseListService } from 'src/app/shared/services/base-list.service';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ApplicationUsersService extends BaseListService {
	protected override endpoint: string = environment.applicationUsersUrl;
	protected override structureType: StructureType = StructureType.USER;

	protected configurationService = inject(ConfigurationService);

	enableUserStatistics$ = this.configurationService.isReady.pipe(
		filter((isReady) => isReady),
		map(() => {
			const enableUserStatistics = this.configurationService.getConfigValue('enableUserStatistics');
			return typeof enableUserStatistics === 'boolean' && enableUserStatistics;
		}),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	protected exportParts: Signal<Parts[] | undefined> = toSignal(
		this.enableUserStatistics$.pipe(
			map((enableUserStatistics) => {
				const parts: Parts[] = enableUserStatistics ? [Parts.LATEST_ACCESS, Parts.LATEST_ACCESS, Parts.ACCESS_COUNT, Parts.LOGIN_COUNT] : [];
				return parts;
			}),
		),
	);

	constructor() {
		super();
	}

	override dispatchStructures(structures: Structure[]): void {
		this.store.dispatch(ApplicationUsersActions.setStructuresSuccess({ userStructures: structures }));
	}

	fetchUserStructures(filters?: SimpleObject, parts: Parts[] = [Parts.EMPTY]) {
		return this.baseApi.getMany<UserStructure>(environment.userStructureUrl, parts, {
			additionalParams: { ...filters, type: StructureType.USER },
		});
	}

	override multipleCount(filters: { structuresUUID?: string[]; structureUUID?: string }) {
		filters.structureUUID = filters.structuresUUID?.join(',');
		delete filters.structuresUUID;

		if (!filters.structureUUID) {
			delete filters.structureUUID;
		}
		return this.baseApi.getOne<Array<{ count: number; uuid: string }>>(environment.applicationUsersUrl, 'count_structures', [], {
			organizationUUID: environment.organizationUUID,
			...filters,
		});
	}

	fetchApplicationUsers(
		pages: number[],
		structureUUID: string,
		sort?: Sort,
		gridSearch?: string | null,
		communityUUID?: string,
		parts?: Parts[],
	): Observable<{ result: ApplicationUser[] | null; paginationInfo?: PaginationInfo }[]> {
		const obs: Observable<{ result: ApplicationUser[] | null; paginationInfo?: PaginationInfo }>[] = [];

		const params: { structureUUID: string; sortBy?: string; q?: string; searchFields?: string; communityUUIDList?: string[] } = {
			structureUUID,
		};

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'firstName,lastName,email,username';
		}
		if (communityUUID) params.communityUUIDList = [communityUUID];

		for (const page of pages) {
			const pagination = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getEntitiesWithPaginationData<ApplicationUser>(
					environment.applicationUsersUrl,
					params,
					parts ?? [],
					undefined,
					pagination,
					undefined,
				),
			);
		}
		return forkJoin(obs);
	}

	deleteUser(user: ApplicationUser) {
		return this.baseApi.deleteEntity(environment.applicationUsersUrl, user);
	}

	override openImportExportModal(structures: DataleanSelectOption<string>[] | null | undefined) {
		const $openModalRes = this.messageHandler.openDialog(
			ImportExportDialogComponent,
			{ title: this.translate.instant('MODALS.IMPORT_EXPORT.MODAL_TITLE') },
			{
				showExport: true,
				showImport: true,
				endpointImport: environment.applicationUsersUrl + 'import/',
				endpointExport: environment.applicationUsersUrl + 'export/',
				structures: structures,
				negativeReflection: true,
				parts: this.exportParts()
			},
		);
		return $openModalRes.result$;
	}
}
