import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
    DataleanSelectOption,
    InputComponent,
    MessageHandler,
    PaginationInfo,
    Parts,
    SelectFieldComponent,
    SimpleObject,
    ToastType,
} from 'addiction-components';
import { LottieModule } from 'ngx-lottie';
import { Observable, map } from 'rxjs';
import { ConfigSelector } from 'src/app/core/state/app.selectors';
import { environment } from 'src/environments/environment';
import { ImportExportService } from '../../services/import-export.service';
import { EntitySelectorDialogInfinityComponent } from '../entity-selector-dialog-infinity/entity-selector-dialog-infinity.component';

@Component({
	selector: 'datalean-import-export-dialog',
	templateUrl: './import-export-dialog.component.html',
	styleUrls: ['./import-export-dialog.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		MatTabsModule,
		MatCheckboxModule,
		LottieModule,
		SelectFieldComponent,
		InputComponent,
		FormsModule,
		ReactiveFormsModule,
	],
	providers: [ImportExportService],
})
export class ImportExportDialogComponent extends EntitySelectorDialogInfinityComponent implements OnInit {
	readonly env = environment;

	private importExportService = inject(ImportExportService);
	private store = inject(Store);
	private messageHandler = inject(MessageHandler);
	private translate = inject(TranslateService);
	//settare a true se serve che oltre a application group e smart group sempre presenti
	//vengano visualizzati anche gli utenti applicativi
	@ViewChild('inputFile') inputFile: ElementRef | undefined;
	/**
	 * booleani per decidere quali tab mostrare in base alla popup che serve visualizzare
	 * */
	@Input() showImport: boolean = false;
	@Input() showExport: boolean = false;
	/**
	 *  Campi che verranno settati a false se non devono essere visualizzati nel form di export
	 * */
	@Input() showSelectedStrcutureUUIDExport = true;
	@Input() showSelectedLangExport = true;
	@Input() showRecipientEmailExport = true;
	/**
	 *  Campi che verranno settati a false se non devono essere visualizzati nel form di import
	 * */
	@Input() showSelectedStrcutureUUIDImport = true;
	@Input() showSelectedLangImport = true;
	@Input() showRecipientEmailImport = true;
	@Input() showInputFileImport = true;

	@Input() endpointImport?: string;
	@Input() endpointExport?: string;
	@Input() additionalInfoPayloadExport? = {};
	@Input() parts?: Parts[] = [];

	@Input() structures: DataleanSelectOption[] | null | undefined;

	response?: string | undefined = undefined;
	availableLang$: Observable<DataleanSelectOption[] | undefined> = this.store.select(ConfigSelector.selectAvailableLanguages).pipe(
		map((languages) => {
			const options: DataleanSelectOption[] = languages.map((lang) => ({ value: lang, label: lang }));
			options.unshift({ value: '', label: this.translate.instant('MODALS.IMPORT_EXPORT.ALL_LANGUAGES') });
			return options;
		})
	);
	constructor() {
		super();
		this.importForm.updateValueAndValidity();
		this.exportForm.updateValueAndValidity();
	}

	ngOnInit(): void {
		if (this.showImport) {
			if (this.showSelectedStrcutureUUIDImport) {
				this.importForm.controls.selectedStrcutureUUID.addValidators(Validators.required);
			} else {
				this.importForm.controls.selectedStrcutureUUID.removeValidators(Validators.required);
			}
			if (this.showRecipientEmailImport) {
				this.importForm.controls.recipientEmail.addValidators([Validators.required, Validators.email]);
			} else {
				this.importForm.controls.recipientEmail.removeValidators([Validators.required, Validators.email]);
			}
		}

		if (this.showExport) {
			if (this.showSelectedStrcutureUUIDExport) {
				this.exportForm.controls.selectedStrcutureUUID.addValidators(Validators.required);
			} else {
				this.exportForm.controls.selectedStrcutureUUID.removeValidators(Validators.required);
			}
			if (this.showRecipientEmailExport) {
				this.exportForm.controls.recipientEmail.addValidators([Validators.required, Validators.email]);
			} else {
				this.exportForm.controls.recipientEmail.removeValidators([Validators.required, Validators.email]);
			}
		}
	}

	importForm = new FormGroup({
		selectedStrcutureUUID: new FormControl('', []),
		selectedLang: new FormControl('', []),
		recipientEmail: new FormControl('', []),
		inputFile: new FormControl(undefined, { validators: [Validators.required] }),
	});

	exportForm = new FormGroup({
		selectedStrcutureUUID: new FormControl('', []),
		selectedLang: new FormControl('', []),
		recipientEmail: new FormControl('', []),
	});

	onPageChanged(pages: number[]) {
		this.currentPage$.next(pages.map((p) => new PaginationInfo(this.pageSize, p)));
	}

	import() {
		const additionalParam: { [key: string]: unknown } = {};

		if (this.showSelectedStrcutureUUIDImport) {
			if (!this.importForm.value.selectedStrcutureUUID) return;
			additionalParam['structureUUID'] = this.importForm.value.selectedStrcutureUUID;
		}

		if (this.showRecipientEmailImport) additionalParam['recipientEmail'] = this.importForm.value.recipientEmail;

		if (this.showSelectedLangImport) additionalParam['locale'] = this.importForm.value.selectedLang || 'all';

		const additionalParams: SimpleObject = {
			structureUUID: additionalParam['structureUUID'] as string,
			recipientEmail: additionalParam['recipientEmail'] as string,
			locale: additionalParam['locale'] as string,
		};
		if (this.endpointImport) {
			this.importExportService.import(this.endpointImport, additionalParams, this.inputFile?.nativeElement.files[0]).subscribe({
				next: () => {
					this.messageHandler.showToast(ToastType.SUCCESS_TOAST, 'IMPORT.UPDATE_SUCCESS');
				},
				error: () => {
					this.messageHandler.showToast(ToastType.ERROR_TOAST, 'IMPORT.ERRORS.UPDATE_FAIL');
				},
			});
		}
	}

	export() {
		const additionalParam: { [key: string]: unknown } = {};
		if (this.showSelectedStrcutureUUIDExport) {
			if (!this.exportForm.value.selectedStrcutureUUID) return;
			additionalParam['structureUUID'] = this.exportForm.value.selectedStrcutureUUID;
		}

		if (this.showRecipientEmailExport) additionalParam['recipientEmail'] = this.exportForm.value.recipientEmail;
		if (this.showSelectedLangExport) additionalParam['locale'] = this.exportForm.value.selectedLang || 'all';

		if (this.endpointExport) {
			let additionalParams = {};
			if (this.additionalInfoPayloadExport) {
				additionalParams = { ...additionalParam, ...this.additionalInfoPayloadExport };
			}
			this.importExportService.export(this.endpointExport, additionalParams, this.parts).subscribe({
				next: (response) => {
					if (response) this.response = response.toString();
					this.messageHandler.showToast(ToastType.SUCCESS_TOAST, 'EXPORT.UPDATE_SUCCESS');
				},
				error: (error) => {
					this.response = error;
					this.messageHandler.showToast(ToastType.ERROR_TOAST, 'EXPORT.ERRORS.UPDATE_FAIL');
				},
			});
		}
	}
}
