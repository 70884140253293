<ng-container>
	<ng-container *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate"></ng-container>
	<ng-container
		*ngIf="tableHeaderTemplate && !isItemsRowsEmpty"
		[ngTemplateOutlet]="tableHeaderTemplate"
		[ngTemplateOutletContext]="{ data: _itemsRows }"></ng-container>
	<cdk-virtual-scroll-viewport
		class="list-viewport"
		[appendOnly]="false"
		[itemSize]="itemSize"
		[ngClass]="{
			'with-header': headerTemplate,
			'with-header-template': tableHeaderTemplate && !isItemsRowsEmpty,
		}"
		(scrolledIndexChange)="onScroll()">
		<div class="list-row" *cdkVirtualFor="let item of _itemsRows" (click)="rowClick.emit(item)">
			<ng-container *ngIf="rowTemplate" [ngTemplateOutlet]="rowTemplate" [ngTemplateOutletContext]="{ item }"></ng-container>
		</div>
		<div class="list-row" *ngIf="isLoading">
			<ng-container *ngIf="loadingTemplate; else defaultLoadingTemplate" [ngTemplateOutlet]="loadingTemplate"> </ng-container>
			<ng-template #defaultLoadingTemplate>
				<div class="single-row-skeleton" *ngFor="let item of skeletonArray">
					<div [class]="class" *ngFor="let class of skeletonConfig">
						@if (class.includes('checkbox')) {
							<span class="checkbox"></span>
						} @else if (class.includes('options')) {
							<span class="button"></span>
						} @else {
							<span class="highlight"></span>
						}
					</div>
				</div>
			</ng-template>
		</div>
	</cdk-virtual-scroll-viewport>
	<ng-container *ngIf="noItemsTemplate && isItemsRowsEmpty" [ngTemplateOutlet]="noItemsTemplate"> </ng-container>
</ng-container>
