import { Asset } from './asset';

export interface Collection {
	uuid: string;
	name: string;
	isPublic: boolean;
	updateDate: Date;
	assets?: Asset[];
	assetsRef: string[];
	/**
	 * Abbiamo aggiunto una logica particolare nella get delle collecion
	 * È possibile aggiungere dei filtri per leggere degli asset dal db
	 * Questa proprietà serve ad indicare se la collection contiene tutti questi asset (ALL)
	 * o alcuni (SOME) oppure nessuno (NONE)
   * Il caso d'uso per cui usiamo questa logica è quello della bulk action
   * di aggiunta di asset nelle collection. In particolare, per pre-valorizzare
   * le checkbox nella lista
	 */
	assetInclusionInfo?: {
		status: 'ALL' | 'SOME' | 'NONE';
	};
}
