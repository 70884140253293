<div class="form-field {{ formControl.status | lowercase }}">
	<label *ngIf="label"
		>{{ label }} <span class="required" *ngIf="_requiredIcon | async">*</span>
		<span class="tooltip" *ngIf="hint" [matTooltip]="hint" [matTooltipPosition]="'right'"></span
	></label>
	<div class="form-input-container {{ iconPosition }} {{ type }}">
		<mat-icon class="form-field-icon" *ngIf="hasIcon">{{ icon }}</mat-icon>
		<input
			[type]="type === 'urlTitle' ? 'text' : type"
			[readonly]="readonly"
			[name]="name"
			[placeholder]="placeholder"
			[formControl]="formControl"
			[autocomplete]="type === 'password' ? 'new-password' : 'off'"
			(blur)="onTouched()"
			(click)="click.emit($event)"
			(keydown)="keydown.emit($event)"
			(mousedown)="$event.stopPropagation()"
			[ngClass]="{ 'invalid-input': invalid }" />

		@if (copyInputDirective) {
			<mat-icon class="append-icon">content_copy</mat-icon>
		} @else if (copy && formControl.value) {
			<mat-icon class="append-icon" (click)="copyToClipboard()">content_copy</mat-icon>
		}
	</div>
	<div>
		<span class="invalid-input-message" *ngIf="invalidMessage">{{ invalidMessage | translate }}</span>
	</div>
</div>
