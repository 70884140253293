import { v4 } from 'uuid';
import { StructureField, UserStructureField } from './structure-field.interface';
import { StructureType } from './structure-type.enum';

export class Structure {
	uuid: string = v4();
	name: string = '';
	description?: StructureDescription[];
	localizedValues?: StructureLocalizedValues[];
	structureFields: StructureField[] = [];
	type: StructureType = StructureType.PRODUCT;
	mobileRatio?: number;
	tabletRatio?: number;
	desktopRatio?: number;
	productVariantsConfig?: string;
	childStructure?: string;
	parentStructure?: string;
	child?: boolean = false;
}

export interface UserStructure extends Omit<Structure, 'structureFields'> {
	structureFields: UserStructureField[];
}

export class StructureDescription {
	language?: string;
	value?: string;
}

export class StructureLocalizedValues {
	constructor(
		public label: string,
		public description: string,
		public locale: string,
	) {}
}
