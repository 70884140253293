import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { PaginationInfo, Parts } from 'addiction-components';
import { Observable, forkJoin } from 'rxjs';
import { PracticeTemplate } from 'src/app/shared/models/informed-consent/practice-template';
import { SpecialHttpHeaderParams } from 'src/app/shared/models/special-header-params.enum';
import { BaseListService } from 'src/app/shared/services/base-list.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PracticeTemplateService extends BaseListService {
	readonly practiceTemplateURL = `${environment.informedConsentUrl}practice-template/`;

	constructor() {
		super();
	}

	/** fatch delle PracticeTemplate in modo paginato */
	fetchPracticeTemplates(
		pages: number[],
		sort?: Sort,
		gridSearch?: string | null,
		communityUUID?: string,
		additionalParmas?: {
			//prendo solo l'ultima versione di ogni sezione
			latest?: boolean;
			uuid?: string;
		}
	): Observable<{ result: PracticeTemplate[] | null; paginationInfo?: PaginationInfo }[]> {
		const obs: Observable<{ result: PracticeTemplate[] | null; paginationInfo?: PaginationInfo }>[] = [];

		const params: {
			sortBy?: string;
			q?: string;
			searchFields?: string;
			communityUUIDList?: string[];
			latest?: boolean;
			uuid?: string;
		} = { ...additionalParmas };

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name';
		}
		if (communityUUID) params.communityUUIDList = [communityUUID];

		for (const page of pages) {
			const pagination = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getEntitiesWithPaginationData<PracticeTemplate>(this.practiceTemplateURL, params, [], undefined, pagination, undefined)
			);
		}
		return forkJoin(obs);
	}

	updatePracticeTemplate(PracticeTemplateUpdated: PracticeTemplate) {
		return this.baseApi.updateOne(this.practiceTemplateURL, PracticeTemplateUpdated.uuid, PracticeTemplateUpdated, []);
	}

	fetchPracticeTemplate(uuid: string, resolvePracticeTemplate: boolean = false, options?: Partial<{redirectIfNotFound: boolean}>) {
		const parts: Parts[] = [];
		if (resolvePracticeTemplate) parts.push('PracticeTemplates' as Parts);

		const header: Record<string, string | string[]> = {};

		if(options?.redirectIfNotFound) {
			header[SpecialHttpHeaderParams.REDIRECT_IF_NOT_FOUND_HEADER_KEY] = 'true'
		}

		return this.baseApi.getEntity<PracticeTemplate>(this.practiceTemplateURL, uuid, parts, {}, header);
	}

	createPracticeTemplate(template: PracticeTemplate) {
		return this.baseApi.createEntity(this.practiceTemplateURL, template, []);
	}

	deletePracticeTemplate(uuid: string) {
		return this.baseApi.deleteOne(this.practiceTemplateURL, uuid);
	}
}
