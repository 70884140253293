import { Injectable, inject } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { DataleanBaseApiService, PaginationInfo, Parts, SimpleObject, SortInfo } from 'addiction-components';
import { Observable, forkJoin, map, tap, zip } from 'rxjs';
import { HeaderLocaleService } from 'src/app/components';
import { CoursesActions } from 'src/app/core/state/app.actions';
import { ApplicationUser, ApplicationUserGroup, ApplicationUserSmartGroup, Course, SubjectType } from 'src/app/shared/models';
import { Recipient } from 'src/app/shared/models/recipient';
import { environment } from 'src/environments/environment';
import { resetCache } from '../state/courses.actions';
import { SpecialHttpHeaderParams } from 'src/app/shared/models/special-header-params.enum';

@Injectable({ providedIn: 'root' })
export class CoursesService {
	private headerSrv = inject(HeaderLocaleService);
	private coursesURL = environment.lmsUrl + 'course';

	constructor(private dataleanApi: DataleanBaseApiService, private store: Store) {}

	getCourses(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: { uuids?: string[]; query?: string; communityUUID?: string };
	}) {
		const { filters, parts, locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = 'localizedValues.name'; // parametrizzare i campi di ricerca??
		}
		if (filters?.communityUUID) params['communityUUID'] = filters?.communityUUID;
		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		return this.dataleanApi.getManyPaged<Course>(this.coursesURL, parts ?? [Parts.ALL], {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	fetchCourses(
		pages: number[],
		sort?: Sort,
		locale?: string,
		gridSearch?: string | null,
		communityUUID?: string
	): Observable<{ result: Course[] | null; paginationInfo?: PaginationInfo }[]> {
		const obs: Observable<{ result: Course[] | null; paginationInfo?: PaginationInfo }>[] = [];

		const params: { sortBy?: string; q?: string; searchFields?: string; locale?: string; communityUUID?: string } = { locale };

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'localizedValues.name';
		}
		if (communityUUID) params.communityUUID = communityUUID;
		for (const page of pages) {
			const pagination = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.dataleanApi.getEntitiesWithPaginationData<Course>(
					this.coursesURL,
					params,
					[Parts.FEATURE_VALUE_LIST, Parts.LESSON_CONTENT_LIST, Parts.RECIPIENTS],
					undefined,
					pagination,
					undefined
				)
			);
		}
		return forkJoin(obs).pipe(
			tap(() => {
				this.getRecipients(locale);
			})
		);
	}

	deleteCourse(course: Course) {
		return this.dataleanApi.deleteEntity(this.coursesURL, course).pipe(
			tap(() => {
				this.store.dispatch(CoursesActions.resetCache());
			})
		);
	}

	getCourse(uuid: string, locale?: string) {
		return this.dataleanApi.getEntity<Course>(this.coursesURL, uuid, [Parts.FEATURE_VALUE_LIST, Parts.LESSON_CONTENT_LIST, Parts.RECIPIENTS], {
			locale,
		}, {[SpecialHttpHeaderParams.REDIRECT_IF_NOT_FOUND_HEADER_KEY]: 'true'});
	}

	createCourse(course: Course, isCloned: boolean) {
		return this.dataleanApi
			.createEntity<Course>(this.coursesURL, course, [Parts.FEATURE_VALUE_LIST, Parts.LESSON_CONTENT_LIST], { cloned: isCloned })
			.pipe(tap(() => this.store.dispatch(resetCache())));
	}

	updateCourse(course: Course) {
		return this.dataleanApi
			.updateEntity<Course>(this.coursesURL, course, [Parts.FEATURE_VALUE_LIST, Parts.LESSON_CONTENT_LIST])
			.pipe(tap(() => this.store.dispatch(resetCache())));
	}

	delete(uuidList: string[]) {
		return this.dataleanApi.deleteMany(this.coursesURL, { uuidList: uuidList.join(',') });
	}

	getRecipients(locale?: string): void {
		const callList = [
			this.dataleanApi
				.getEntities<ApplicationUser[]>(environment.applicationUsersUrl, { locale }, [Parts.EMPTY])
				.pipe(map((users) => users.map((u) => ({ ...u, type: SubjectType.APPLICATION_USER })))),
			this.dataleanApi
				.getEntities<ApplicationUserGroup[]>(environment.groupsUrl, { locale }, [Parts.EMPTY])
				.pipe(map((users) => users.map((u) => ({ ...u, type: SubjectType.APPLICATION_GROUP })))),
			this.dataleanApi
				.getEntities<ApplicationUserSmartGroup[]>(environment.smartGroupsUrl, { locale }, [Parts.EMPTY])
				.pipe(map((users) => users.map((u) => ({ ...u, type: SubjectType.APPLICATION_SMART_GROUP })))),
		];

		zip(...callList).subscribe({
			next: (recipientGroupList) => {
				this.store.dispatch(
					CoursesActions.setRecipients({
						recipients: recipientGroupList.flatMap((l) =>
							l.map((i: Recipient) => ({ uuid: i.uuid, type: i.type, name: i.name, username: i.username }))
						),
					})
				);
			},
		});
	}
}
