import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, map, catchError, of, Observable } from 'rxjs';
// se non lo vedi runna npm run i18n:cache-busting
import cacheBusting from 'src/assets/i18n/i18n-cache-busting.json';

export function translateLoader(http: HttpClient) {
	return new MultiTranslateHttpLoader(http, [
		{ prefix: './assets/i18n/', suffix: '.json' },
		{ prefix: './profiles/default/i18n/', suffix: '.json' },
	]);
}

export class MultiTranslateHttpLoader implements TranslateLoader {
	constructor(
		private http: HttpClient,
		public resources: { prefix: string; suffix: string }[] = [
			{
				prefix: '/assets/i18n/',
				suffix: '.json',
			},
		]
	) {}

	public getTranslation(lang: string): Observable<unknown> {
		return forkJoin(
			this.resources.map((config) => {
				return this.http.get(`${config.prefix}${lang}${config.suffix}?v=${(cacheBusting as Record<string, string>)[lang]}`).pipe(catchError(() => of({})));
			})
		).pipe(
			map((response) => {
				return this.mergeDeep(...(response as Record<string, unknown>[]));
			}),
			catchError((error: unknown) => {
				return of(error);
			})
		);
	}

	private mergeDeep<T extends Record<string, unknown>>(...objects: T[]) {
		const isObject = (obj: unknown) => obj && typeof obj === 'object';
		return objects.reduce((prev: Record<string, unknown>, obj) => {
			Object.keys(obj).forEach((key) => {
				const pVal = prev[key];
				const oVal = obj[key];
				if (Array.isArray(pVal) && Array.isArray(oVal)) {
					prev[key] = pVal.concat(...oVal);
				} else if (isObject(pVal) && isObject(oVal)) {
					prev[key] = this.mergeDeep(pVal as Record<string, unknown>, oVal as Record<string, unknown>);
				} else {
					prev[key] = oVal;
				}
			});
			return prev;
		}, {});
	}
}
