import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataleanBaseApiService, Parts, SimpleObject } from 'addiction-components';
import { Observable } from 'rxjs';
@Injectable()
export class ImportExportService {
	constructor(private baseApi: DataleanBaseApiService) {}

	import(endpoint: string, additionalParam: SimpleObject = {}, file: File): Observable<FormData> {
		const fileInput = new FormData();
		fileInput.append('file', file);

		return this.baseApi.createOne<FormData>(`${endpoint}`, fileInput, [Parts.ALL], additionalParam);
	}

	export(endpoint: string, additionalParams: { [key: string]: unknown } = {}, parts: Parts[] = []): Observable<unknown> {
		const options: Parameters<HttpClient['get']>[1] = {};
		const optionWithRepsonseType = { ...options };
		return this.baseApi.getOneS(
			`${endpoint}`,
			'',
			[Parts.EXPORT, Parts.FEATURE_VALUE_LIST, Parts.RELATED_PRODUCTS_LIST, Parts.STRUCTURE_FIELDS, Parts.RELATED, Parts.VALUES, ...parts],
			additionalParams,
			{ ...optionWithRepsonseType, observe: 'body', responseType: 'text' }
		);
	}
}
