import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
	Validators,
} from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SharedModule } from '../../modules/shared.module';
import { lowercase, mustMatch, number, special, uppercase } from '../../utils';

/**
 * Per utilizzare al meglio questo componnte, occorre prendere il riferimento
 * del form con un formControlName nel form padre
 *
 */
@Component({
	selector: 'addiction-password-form',
	templateUrl: './password-form.component.html',
	standalone: true,
	imports: [CommonModule, SharedModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			//se implementi ControlValueAccessor
			provide: NG_VALUE_ACCESSOR, //chiave di dipendenza (token di iniezione)
			multi: true, //true perchè altri componenti potrebbero aver usato lo stesso 'token di iniezione'
			useExisting: forwardRef(() => PasswordFormComponent),
		},
		{
			//se implementi Validator
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => PasswordFormComponent),
		},
	],
})
export class PasswordFormComponent implements ControlValueAccessor, Validator {
	changePasswordForm = new FormGroup<PasswordFormComponentFormInterface>(
		{
			newPassword: new FormControl('', {
				validators: [Validators.minLength(8), lowercase(), uppercase(), special(), number()],
				nonNullable: true,
			}),
			confirmNewPassword: new FormControl('', [Validators.minLength(8)]),
		},
		{ validators: mustMatch('newPassword', 'confirmNewPassword') },
	);

	passwordErrorMessage: Record<string, string> = {
		LENGTH: '',
		LOWER: '',
		UPPER: '',
		NUMBER: '',
		SPECIAL: '',
	};

	@Input() unifyMessages = false;

	onChangeSubs: Subscription[] = []; //usato per notificare i singoli cambiamenti

	requiredIcon = new BehaviorSubject<boolean>(false);

	constructor() {}

	onTouched = () => {};

	//Validator
	/**
	 * metodo usato per validare il valore corrente del formControl
	 * viene richiamato ogni volta che un nuovo valore viene settato nel form padre
	 * DEVE ritornare null se non ha errori
	 *      ritornare un oggetto errore, in caso di errori
	 */
	validate(control: AbstractControl<any, any>): ValidationErrors | null {
		// console.log('validate', control.hasValidator(Validators.required));
		this.requiredIcon.next(control.hasValidator(Validators.required));

		if (this.changePasswordForm.valid) {
			return null;
		}

		return this.passwordErrorMessage;
	}

	//ControlValueAccessor
	writeValue(obj: any): void {
		this.changePasswordForm.patchValue(obj, { emitEvent: false });
	}

	registerOnChange(fn: any): void {
		const sub = this.changePasswordForm.valueChanges.subscribe(fn);
		this.onChangeSubs.push(sub);
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
		if (disabled) this.changePasswordForm.disable({ emitEvent: false });
		else this.changePasswordForm.enable({ emitEvent: false });
	}

	ngOnDestroy() {
		for (let sub of this.onChangeSubs) {
			sub.unsubscribe();
		}
	}

	togglePasswordType(passwordField: HTMLInputElement) {
		passwordField.type = passwordField.type === 'password' ? 'text' : 'password';
	}
}

export interface PasswordFormComponentFormInterface {
	newPassword: FormControl;
	confirmNewPassword: FormControl;
}
