import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
	Asset,
	AssetType,
	DataleanBaseApiService,
	FeatureValue,
	FeatureValueType,
	FilterMenuField,
	FilterMenuFieldDateLabel,
	FilterMenuSection,
	FilterMenuSectionType,
	FilterSort,
	PaginationInfo,
	Parts,
	SearchInfo,
	SortInfo,
} from 'addiction-components';
import { BehaviorSubject, Observable, combineLatest, debounceTime, forkJoin, switchMap } from 'rxjs';
import { FilterData, MediaLibraryAvailableFilters, MediaLibraryFilters } from 'src/app/pages/media-library/state/media-library.state';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { AssetIdentifierService } from 'src/app/shared/services/asset-identifier.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AssetSelectorService {
	private _gridData = signal<{ items: Asset[][]; pages: number[]; totalItems: number }>({ items: [], pages: [0], totalItems: -1 });
	private _features = signal<FeatureValue[]>([]);
	private _availableFilters = signal<MediaLibraryAvailableFilters | undefined>(undefined);
	private _activeFilters = signal<MediaLibraryFilters | undefined>({});
	private _lastSelectedFeature = signal<FeatureValue | undefined>(undefined);
	private _searchValue = signal<string | null>(null);
	private _sortOption = signal<FilterSort | undefined>(undefined);
	private _pages = signal<number[]>([0]);
	private _assetType = signal<AssetType | undefined>(undefined);
	private _format = signal<string | undefined>(undefined);
	private _selectedElements = signal<Set<string>>(new Set());
	private _status = signal<STATE_STATUS>(STATE_STATUS.LOADING);
	private apiService = inject(DataleanBaseApiService);
	private assetIdentifier = inject(AssetIdentifierService);

	status = computed(() => this._status());
	searchValue = computed(() => this._searchValue());
	gridData = computed(() => this._gridData());
	pages = computed(() => this._pages());
	features = computed(() => this._features());
	availableFilters = computed(() => this._availableFilters());
	sortOption = computed(() => this._sortOption());
	lastSelectedFeature = computed(() => this._lastSelectedFeature());
	activeFilters = computed(() => this._activeFilters());
	assetType = computed(() => this._assetType());
	format = computed(() => this._format());
	selectedElements = computed(() => this._selectedElements());
	private apiParams = computed(() => {
		const params: {
			filters?: MediaLibraryFilters;
			searchInfo?: SearchInfo;
			sortInfo?: SortInfo;
			pages: number[];
			assetType?: AssetType;
			format?: string;
		} = {
			pages: [0],
		};
		const filters = this.activeFilters();
		const searchValue = this.searchValue();
		const pages = this.pages();
		const sortOption = this.sortOption();
		const assetType = this.assetType();
		const format = this.format();
		// console.log('apiParams', filters, searchValue, pages);

		if (filters) {
			params.filters = Object.fromEntries<MediaLibraryFilters>(
				Object.entries(filters).filter(([, v]) => v !== undefined && v != null && v?.length !== 0)
			);
		}
		if (searchValue) {
			params.searchInfo = new SearchInfo('name', searchValue, 'searchFields', 'q');
		}
		if (pages?.length) {
			params.pages = pages;
		}

		if (assetType) {
			params.assetType = assetType;
		}

		if (format) {
			params.format = format;
		}

		if (sortOption && sortOption.value.includes('#')) {
			const [fieldName, direction] = sortOption.value.split('#');
			params.sortInfo = new SortInfo(fieldName, direction);
		} else {
			params.sortInfo = new SortInfo('name', 'asc');
		}

		return params;
	});
	filterMenuSections = computed(() => {
		const availableFilters = this._availableFilters();
		const activeFilters = this.activeFilters();
		return this.createFilterMenu(activeFilters, availableFilters?.filterData);
	});

	reload$ = new BehaviorSubject<boolean>(true);
	fetchFeatureValues$ = this.apiService.getEntities<FeatureValue[]>(
		environment.featureValueUrl,
		{
			type: FeatureValueType.ASSET,
			parentUUID: '',
		},
		[Parts.FEATURE_VALUES],
		undefined,
		undefined,
		new SortInfo('order', 'asc')
	);
	fetchAvailableFilters$ = combineLatest([toObservable(this.lastSelectedFeature), toObservable(this._activeFilters)]).pipe(
		switchMap(([lastSelectedFeature, activeFilters]) => {
			return this.fetchAvailableFilters(lastSelectedFeature?.uuid, activeFilters);
		})
	);
	fetchAssets$ = combineLatest([toObservable(this.apiParams), this.reload$])
		.pipe(debounceTime(100))
		.pipe(
			switchMap(([{ filters, pages, searchInfo, sortInfo }]) => {
				this.setStatus(STATE_STATUS.LOADING);
				const calls: Observable<{ result: Asset[] | null; paginationInfo?: PaginationInfo | undefined }>[] = [];
				for (const page of pages) {
					const additionalParams = { ...filters, parentUUID: 'all' };
					if (filters?.createFrom) {
						additionalParams['createDate'] = 'r|' + filters.createFrom;
					}
					if (filters?.createTo) {
						if (additionalParams['createDate']) {
							additionalParams['createDate'] += '|' + filters.createTo;
						} else {
							additionalParams['createDate'] = 'r||' + filters.createTo;
						}
					}

					calls.push(
						this.apiService.getEntitiesWithPaginationData<Asset>(
							environment.mediaLibraryUrl,
							additionalParams,
							[Parts.PREVIEW_ASSET_INFO],
							searchInfo,
							new PaginationInfo(environment.pageSize, page),
							sortInfo
						)
					);
				}
				return forkJoin(calls);
			})
		);

	//avere il dataleanApiService rende non standalone
	constructor() {
		this.fetchFeatureValues$.pipe(takeUntilDestroyed()).subscribe((features) => {
			this.setFeatures(features);
		});

		this.fetchAvailableFilters$.pipe(takeUntilDestroyed()).subscribe((availableFilters) => {
			this.setAvailableFilters(availableFilters);
		});

		this.fetchAssets$.pipe(takeUntilDestroyed()).subscribe((data) => {
			const startingResult: { pages: number[]; assets: Asset[][]; totalAssetCount: number } = {
				pages: [],
				assets: [],
				totalAssetCount: 0,
			};
			const result = data.reduce((acc, item) => {
				if (item.paginationInfo) {
					acc.pages.push(item.paginationInfo.numberOfPage);
					acc.totalAssetCount = item.paginationInfo.totalNumberOfElements;
					if (item.result) {
						acc.assets[item.paginationInfo.numberOfPage] = item.result;
					}
				}
				return acc;
			}, startingResult);
			this.setStatus(STATE_STATUS.READY);
			this.setGridData(result.assets, result.pages, result.totalAssetCount);
		});

		this.reload$.pipe(takeUntilDestroyed()).subscribe({
			next: () => {
				this.setStatus(STATE_STATUS.LOADING);
			},
		});
	}

	setFeatures(features: FeatureValue[]) {
		this._features.set(features);
	}

	setActiveFilters(filters: MediaLibraryFilters) {
		// console.log('setActiveFilters');

		this._activeFilters.set(filters);
	}
	updateActiveFilters(filters: Partial<MediaLibraryFilters>) {
		// console.log('updateActiveFilters');

		this._activeFilters.set({ ...filters });
	}
	setAvailableFilters(filters: MediaLibraryAvailableFilters) {
		// console.log('setAvailableFilters');

		this._availableFilters.set(filters);
	}
	setGridData(assets: Asset[][], pages: number[], totalItems: number) {
		this._gridData.set({ items: assets, pages, totalItems });
	}
	setSearchValue(text: string | null) {
		// console.log('setSearchValue');

		this._searchValue.set(text);
	}
	setPages(pages: number[]) {
		// console.log('setPages');
		this._pages.set(pages);
	}
	setAssetType(assetType: AssetType) {
		// console.log('assetType');
		this._assetType.set(assetType);
	}
	setFormat(format: string) {
		// console.log('format');
		this._format.set(format);
	}
	setSortOption(option: FilterSort) {
		// console.log('setSortOption');
		this._sortOption.set(option);
	}

	/**used for multiple selection */
	toggleSelectedElements(uuid: string) {
		this._selectedElements.update((selectedElements) => {
			if (selectedElements.has(uuid)) {
				selectedElements.delete(uuid);
			} else {
				selectedElements.add(uuid);
			}
			return structuredClone(selectedElements);
		});
	}
	/**used for single selection */
	toggleSelectedElement(uuid: string) {
		this._selectedElements.update((selectedElements: Set<string>) => {
			if (selectedElements.has(uuid)) {
				selectedElements = new Set();
			} else {
				selectedElements = new Set([uuid]);
			}
			return selectedElements;
		});
	}
	setSelectedElements(uuid: string) {
		this._selectedElements.set(new Set([uuid]));
	}
	private setStatus(status: STATE_STATUS) {
		this._status.set(status);
	}

	private fetchAvailableFilters(parentFeatureUUID?: string, filterState?: MediaLibraryFilters): Observable<MediaLibraryAvailableFilters> {
		//FILTRI
		let assetType, createDate, updateDate, format, tags, querySearch;
		if (filterState) {
			if (filterState.assetType && filterState.assetType.length) {
				//con array vuoto tengo il defaut
				assetType = filterState.assetType?.join(',');
			}

			if (filterState.tags) {
				tags = filterState.tags;
			}

			//calcolo DATE
			if (filterState.createFrom) {
				createDate = 'r|' + filterState.createFrom;
			}
			if (filterState.createTo) {
				if (createDate) createDate += '|' + filterState.createTo;
				else createDate = 'r||' + filterState.createTo;
			}
			if (filterState.updateFrom) {
				updateDate = 'r|' + filterState.updateFrom;
			}
			if (filterState.updateTo) {
				if (updateDate) updateDate += '|' + filterState.updateTo;
				else updateDate = 'r||' + filterState.updateTo;
			}

			//FORMATO
			if (filterState?.format) {
				format = filterState.format;
			}

			//SEARCH
			if (filterState.q) {
				querySearch = filterState.q;
			}
		}

		const param: {
			featureParentUUID?: string;
			assetType?: AssetType | string;
			featureValueList?: string;
			tags?: string;
			format?: string;
			createDate?: string;
			updateDate?: string;
			q?: string; //query fatta dal utente
			searchFields?: string; //quando utente fa la query passarlo con name:q
		} = {};

		if (parentFeatureUUID) param.featureParentUUID = parentFeatureUUID;
		if (format) param.format = format;
		if (assetType) param.assetType = assetType;
		if (tags) param.tags = tags;
		if (querySearch) {
			param.q = querySearch;
			param.searchFields = 'name';
		}
		//DATE
		if (createDate) param.createDate = createDate;
		if (updateDate) param.updateDate = updateDate;

		return this.apiService.getEntities<MediaLibraryAvailableFilters>(environment.mediaLibraryUrl + 'filters', param, []);
	}
	private createFilterMenu(filter: MediaLibraryFilters = {}, availableFilters?: FilterData): FilterMenuSection[] {
		if (!availableFilters) {
			return [];
		}

		const result = [];

		const assetSection: FilterMenuSection<unknown> = {
			id: 'assetType',
			isExpanded: (filter?.assetType?.length ?? -1) > 0,
			titleSection: 'FILTER_MENU.NAMES.ASSET_TYPE',
			sectionFilterType: FilterMenuSectionType.checkbox,
			fields: Object.keys(availableFilters.assetType).map((el) => {
				//check se era già fleggato
				const isChecked: boolean = !!filter.assetType?.includes(el);
				return { checked: isChecked, value: el, label: el };
			}),
		};

		const formatSection: FilterMenuSection<string> = {
			id: 'format',
			isExpanded: false,
			titleSection: 'FILTER_MENU.NAMES.FORMAT',
			sectionFilterType: FilterMenuSectionType.checkbox,
			fields: Object.keys(availableFilters.format).map((el) => {
				//check se era già fleggato
				const isChecked: boolean = !!filter.format?.includes(el);
				return { checked: isChecked, value: el, label: this.assetIdentifier.getFormatLabel(el) };
			}),
		};

		const tagsSection: FilterMenuSection<unknown> = {
			id: 'tags',
			isExpanded: false,
			titleSection: 'FILTER_MENU.NAMES.TAGS',
			sectionFilterType: FilterMenuSectionType.tags,
			fields: Array.isArray(availableFilters.tags)
				? availableFilters.tags.map((el) => {
						const isChecked: boolean = !!filter.tags?.includes(el.uuid);
						return { value: el.uuid, checked: isChecked, label: el.name };
				  })
				: [],
		};

		//date
		const createDateFields: FilterMenuField<Date>[] = [];
		if (filter.createFrom) createDateFields.push({ label: FilterMenuFieldDateLabel.FROM, checked: false, value: new Date(filter.createFrom) });
		if (filter.createTo) createDateFields.push({ label: FilterMenuFieldDateLabel.TO, checked: false, value: new Date(filter.createTo) });
		const dateSectionCreation: FilterMenuSection<unknown> = {
			id: 'dateCreation',
			isExpanded: false,
			titleSection: 'FILTER_MENU.CREATION_DATE_FROM',
			secondTitleSection: 'FILTER_MENU.CREATION_DATE_TO',
			sectionFilterType: FilterMenuSectionType.rangeDate,
			fields: createDateFields,
		};

		const updateDateFields: FilterMenuField<Date>[] = [];
		if (filter.updateFrom) updateDateFields.push({ label: FilterMenuFieldDateLabel.FROM, checked: false, value: new Date(filter.updateFrom) });
		if (filter.updateTo) updateDateFields.push({ label: FilterMenuFieldDateLabel.TO, checked: false, value: new Date(filter.updateTo) });
		const dateSectionUpdate: FilterMenuSection<unknown> = {
			id: 'dateUpdate',
			isExpanded: false,
			titleSection: 'FILTER_MENU.UPDATE_DATE_FROM',
			secondTitleSection: 'FILTER_MENU.UPDATE_DATE_TO',
			sectionFilterType: FilterMenuSectionType.rangeDate,
			fields: updateDateFields,
		};

		if (assetSection.fields.length) result.push(assetSection);
		if (formatSection.fields.length) result.push(formatSection);
		if (tagsSection.fields.length) result.push(tagsSection);
		result.push(dateSectionCreation, dateSectionUpdate);

		return result;
	}
}
