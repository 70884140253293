import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { Observer } from 'rxjs';
import { DataleanSelectOption } from '../../models';
import { SharedModule } from '../../modules/shared.module';
import { ToLocalizedValuePipe } from '../../pipes/to-localized-value.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'addiction-select',
	templateUrl: './select-field.component.html',
	standalone: true,
	imports: [CommonModule, SharedModule, MatFormFieldModule, MatSelectModule, TranslateModule, ToLocalizedValuePipe],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SelectFieldComponent,
		},
	],
})
export class SelectFieldComponent<T> implements ControlValueAccessor, OnInit, OnChanges {
	@Input() label: string = '';
	@Input() options: DataleanSelectOption<T>[] | null | undefined;
	@Input() materialStyle: boolean = true;
	@Input() optGroup: boolean = false;
	@Input() optGroupOptions: Array<{
		name: string;
		options: DataleanSelectOption[];
	}> = [];
	@Input() disabled: boolean = false;
	@Input() readonly: boolean = false;
	@Input() value?: T;
	@Input() placeholder?: string;
	@Input() multiple?: boolean;

	@Output() valueChange = new EventEmitter<T | undefined>();

	protected destroyRef = inject(DestroyRef);

	formControl = new FormControl<T | null>(null);

	constructor() {}

	ngOnInit(): void {
		if (this.value) this.formControl.patchValue(this.value);
		if (this.disabled) this.formControl.disable();
		this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((val) => {
			this.valueChange.emit(val ?? undefined);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('value' in changes) {
			this.formControl.patchValue(this.value ?? null);
		}
	}

	onTouched: () => void = () => {};

	writeValue(obj: T): void {
		this.formControl.patchValue(obj);
		this.formControl.updateValueAndValidity();
	}

	registerOnChange(fn: Observer<T | null>): void {
		this.formControl.valueChanges.subscribe(fn);
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
		if (disabled) this.formControl.disable({ emitEvent: false });
		else this.formControl.enable({ emitEvent: false });
	}
}
