import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import {
	DataleanBaseApiService,
	DataleanPagedResult,
	ExtraStructureFieldType,
	PaginationInfo,
	Parts,
	Structure,
	StructureType,
} from 'addiction-components';
import { Observable, forkJoin, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SpecialHttpHeaderParams } from '../models/special-header-params.enum';
@Injectable({ providedIn: 'root' })
export class StructuresService {
	constructor(private baseApi: DataleanBaseApiService) {}

	fetchStructures(
		type: StructureType,
		pages: number[],
		sort?: Sort,
		locale?: string,
		gridSearch?: string | null,
		child?: boolean,
		pageSize?: number,
	): Observable<DataleanPagedResult<Structure>[]> {
		const obs: Observable<DataleanPagedResult<Structure>>[] = [];

		const params: { sortBy?: string; q?: string; searchFields?: string; locale?: string; type?: StructureType[]; child?: boolean } = { locale };

		if (type) params['type'] = [type];

		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name,description';
		}
		if (child) params.child = child;

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(pageSize ?? environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Structure>(environment.structureUrl, [Parts.STRUCTURE_FIELDS], {
					pagination,
					additionalParams: params,
				}),
			);
		}
		return forkJoin(obs);
	}

	getStructure(uuid: string, locale: string = 'all', child?: boolean, options?: Partial<{redirectIfNotFound: boolean}>) {
		const header: Record<string, string | string[]> = {};

		if(options?.redirectIfNotFound) {
			header[SpecialHttpHeaderParams.REDIRECT_IF_NOT_FOUND_HEADER_KEY] = 'true';
		}

		const params: { locale?: string; child?: boolean } = { locale };
		if (child) params.child = child;
		return this.baseApi.getOne<Structure>(environment.structureUrl, uuid, [Parts.STRUCTURE, Parts.STRUCTURE_FIELDS], params, header);
	}

	updateStructure(structure: Structure): Observable<Structure> {
		const { uuid } = structure;
		return this.baseApi.updateOne<Structure>(environment.structureUrl, uuid, structure, [Parts.EMPTY]);
	}

	createStructure(structure: Structure): Observable<Structure> {
		return this.baseApi.createOne<Structure>(environment.structureUrl, structure, [Parts.EMPTY]);
	}

	cloneStructure(structure: Structure): Observable<Structure> {
		return this.baseApi.createOne<Structure>(environment.structureUrl + 'clone', structure, [Parts.STRUCTURE]);
	}

	deleteStructure(uuid: string): Observable<true> {
		return this.baseApi.deleteOne(environment.structureUrl, uuid).pipe(map(() => true));
	}

	getPathFromStructureType(structureType: StructureType | undefined) {
		let url = '';
		switch (structureType) {
			case StructureType.PRODUCT:
				url = 'products';
				break;
			case StructureType.PRODUCTVARIANTS:
				url = 'products-variants';
				break;
			case StructureType.COMMUNICATION:
				url = 'communications';
				break;
			case StructureType.COCKPIT:
				url = 'useful-links';
				break;
			case StructureType.CONTENT:
				url = 'contents';
				break;
			case StructureType.BOOKABLE_PRODUCT:
				url = 'reservations/products';
				break;
			case StructureType.RESOURCE:
				url = 'reservations/resources';
				break;
			case StructureType.USER:
				url = 'idm/app-users';
		}
		return url;
	}

	getDesktopRatio(type: ExtraStructureFieldType | undefined): number[] {
		let availableDesktopRatio = [100, 90, 85, 80, 75, 66, 50, 33, 25, 20, 15, 10];
		switch (type) {
			case ExtraStructureFieldType.EMAIL_3:
			case ExtraStructureFieldType.ATTACHMENT_3:
			case ExtraStructureFieldType.FILE_3:
			case ExtraStructureFieldType.FEATURE_VALUES_CONTAINER:
			case ExtraStructureFieldType.HTML:
				availableDesktopRatio = availableDesktopRatio.filter((ratio) => ratio >= 50);
				break;
		}

		return availableDesktopRatio;
	}
}
