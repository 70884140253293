import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, forwardRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AutocompleteOption, DataleanPagedResult, Structure, StructureField, StructureType } from 'addiction-components';
import {
	BehaviorSubject,
	catchError,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	Observable,
	of,
	scan,
	shareReplay,
	startWith,
	switchMap,
	tap,
} from 'rxjs';
import { Permission } from '../../models';
import { Substructure } from '../../models/substructure.interface';
import { PermissionService } from '../../services/permission.service';
import { StructuresService } from '../../services/structures.service';
import { ContainerComponent } from '../container/container.component';
import { DataleanAutocompleteComponent } from '../datalean-autocomplete/datalean-autocomplete.component';

@Component({
	selector: 'datalean-substructure',
	templateUrl: './substructure.component.html',
	styleUrls: ['./substructure.component.scss'],
	standalone: true,
	imports: [
		DataleanAutocompleteComponent,
		CommonModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatAutocompleteModule,
		ReactiveFormsModule,
		AsyncPipe,
		forwardRef(() => ContainerComponent),
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SubstructureComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubstructureComponent implements ControlValueAccessor, OnInit {
	@Input() field: StructureField | undefined;
	@Input() type: StructureType | undefined;
	protected destroyRef = inject(DestroyRef);
	protected store = inject(Store);
	protected router = inject(Router);

	//#region variabili private
	private strucutresService = inject(StructuresService);
	private permissionService = inject(PermissionService);
	private translateService = inject(TranslateService);

	/**
	 * Questa mappa è necessaria perchè il componente autocomplete torna sempre e solo la label
	 * quindi devo recuperare lo uuid della struttura dal nome
	 */
	private structureUuidMap = new Map<string, string>();

	//#endregion

	//#region variabili pubbliche
	public selectedStructureUUID$ = new BehaviorSubject<string | null>(null);

	public formGroup = new UntypedFormGroup({
		substructureSelector: new FormControl<string | null>(null),
		substructureUUID: new FormControl<string | null>(null),
	});

	public pagesToLoad$ = new BehaviorSubject<number[]>([0]);

	/**
	 * Recupera le opzioni utilizzate dal componente autocomplete al cambio di valore all'input dell'autocomplete
	 * Il valore dell'input viene passato al metodo per il recupero delle strutture se ha 3 o più caratteri.
	 * Alla fetch structure viene passato il parametro per recuperare solo le strutture di tipo 'product' e child = true
	 * La risposta viene poi mappata in AutocompleteOption impostato il nome come label e lo uuid come value.
	 * Vengono anche impostati i valori della structureUuidMap necessaria per il recupero dello uuid della struttura in base al nome.
	 * Questa mappa è necessaria dato che alla selezione dell'autocomplete viene sempre tornata la label e non il value.
	 */
	public options$: Observable<AutocompleteOption[]> = combineLatest([
		this.pagesToLoad$,
		this.formGroup.valueChanges.pipe(
			startWith(this.formGroup.value),
			map((values) => values?.substructureSelector),
			distinctUntilChanged(),
		),
	]).pipe(
		debounceTime(300),
		switchMap(([pages, substructureSelector]) => {
			if (!substructureSelector || substructureSelector.length < 3) {
				this.pagesToLoad$.next([0]);
				return of(null);
			}

			if (!this.type) return of(null);

			this.isLoading$.next(true);
			return this.strucutresService
				.fetchStructures(
					this.type,
					[pages[pages.length - 1]],
					{ active: 'name', direction: 'asc' },
					this.translateService.currentLang ?? this.translateService.defaultLang,
					substructureSelector,
					true,
					20,
				)
				.pipe(
					map((structure) => {
						const structureResponse: { pages: number[]; structure: DataleanPagedResult<Structure>[] } | null = {
							pages,
							structure,
						};
						return structureResponse;
					}),
					catchError(() => {
						this.isLoading$.next(false);
						return of(null);
					}),
				);
		}),
		scan((acc: AutocompleteOption[], structureResponse: { pages: number[]; structure: DataleanPagedResult<Structure>[] } | null) => {
			if (!structureResponse || !structureResponse.structure || !structureResponse.structure[0]) {
				this.selectedStructureUUID$.next(null);
				return [];
			}

			const newOptions: AutocompleteOption[] = structureResponse.structure[0].result.map((structure) => {
				let structureDescription = '';

				if (structure.description) {
					structureDescription =
						structure.description.find(
							(description) => description.language === (this.translateService.currentLang ?? this.translateService.defaultLang),
						)?.value ?? '';
				}

				let label = structure.name;
				if (structureDescription !== '') {
					label = `${label} - ${structureDescription}`;
				}

				this.structureUuidMap.set(label, structure.uuid);

				return {
					label,
					value: structure.uuid,
				};
			});

			this.isLoading$.next(false);

			if (structureResponse.pages.length === 1) return newOptions;

			return [...acc, ...newOptions];
		}, [] as AutocompleteOption[]),
	);

	/**
	 * Variabile che rappresenta lo stato di caricamento delle sotto strutture nell'autocomplete.
	 * Utile per mostrare lo spinner di caricamento
	 */
	public isLoading$ = new BehaviorSubject<boolean>(false);

	/**
	 * Al cambio del valore di selectedStructureUUID$ viene eseguita la chiamata del get della struttura in base allo UUID.
	 * La struttura viene poi usata per settare il parametro structureFields nel componente container passato gli structureFields della stuttura.
	 */
	public selectedStructure$: Observable<Structure | null> = this.selectedStructureUUID$.pipe(
		distinctUntilChanged((prev, curr) => {
			return prev !== null && curr !== null && prev === curr;
		}),
		switchMap((selectedStructureUUID) => {
			if (selectedStructureUUID) return this.strucutresService.getStructure(selectedStructureUUID, 'all', true).pipe(catchError(() => of(null)));

			return of(null);
		}),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	public hasReadPermission$: Observable<boolean> = this.permissionService.checkUserPermission(Permission.READ_SUB_STRUCTURE);

	public onTouched: () => void = () => {};

	public onValidatorChange = () => {};

	/**
	 * serve per impostare i valori di default (o riempiere il form con i vecchi valori)
	 */
	public dataset?: unknown;
	//#endregion

	constructor() {}
	ngOnInit(): void {
		/**
		 * Se substructureSelector che contiene il nome della struttura selezionata non è presente
		 * viene recuperato tramite la get sulla struttura dato lo UUID.
		 * Questo serve perchè lo script di popolamento dei dati non setta il nome
		 * ma è necessario per settarlo correttamente nel componente autocomplete.
		 */
		this.selectedStructure$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((selectedStructure) => {
			if (!selectedStructure) return;

			this.formGroup.patchValue({ substructureUUID: selectedStructure?.uuid, substructureSelector: selectedStructure?.name }, { emitEvent: false });
		});
	}

	//#region funzioni pubbliche
	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	public writeValue(obj: Substructure | undefined): void {
		if (!obj) return;

		if (obj.substructureSelector) {
			this.formGroup.patchValue({ substructureUUID: obj.substructureUUID, substructureSelector: obj.substructureSelector }, { emitEvent: false });
		}

		if (obj?.substructureUUID) this.valueChangedSelectAutocomplete(obj.substructureUUID, true);
		this.dataset = obj;

		if (obj.substructureSelector) return;
	}

	public registerOnChange(fn: (val: unknown | undefined) => void): void {
		this.formGroup.valueChanges.subscribe(fn);
	}

	/**
	 * Evento che scatta alla selezione della tendina nel componente autocomplete.
	 * Viene rimosso il control relativo alla sottostruttura contenuto nel container component e resettata il dataset.
	 * Il valore tornato viene cercato nella mappa per recuperare lo uuid, se isUuid è true il valore passato è già lo uuid.
	 * Lo uuid viene poi impostato al BehaviorSubject selectedStructureUUID$.
	 */
	public valueChangedSelectAutocomplete(newValue: string, isUuid = false): void {
		this.removeControlsExcept(['substructureSelector', 'substructureUUID']);
		this.dataset = null;
		let structureUuid: string;

		if (isUuid) structureUuid = newValue;
		else structureUuid = this.structureUuidMap.get(newValue) ?? '';

		this.selectedStructureUUID$.next(structureUuid);

		this.formGroup.patchValue({ substructureUUID: structureUuid }, { emitEvent: false });
	}

	public pageChanges(pages: number[]): void {
		this.pagesToLoad$.next(pages);
	}

	//#endregion

	//#region funnzioni private

	/**
	 * Questa funzione è necessaria per rimuovere il form control della sotto struttura aggiunta alla selezione dell'autocomplete.
	 * Non potendo sapere il nome del control da rimuovere dato che cambia in base alla struttura selezionata
	 * vengono passati i controlli da NON rimuovere.
	 */
	private removeControlsExcept(controlNamesToKeep: string[]) {
		const controls = this.formGroup.controls;

		for (const controlName in controls) {
			if (controls.hasOwnProperty(controlName) && !controlNamesToKeep.includes(controlName)) {
				this.formGroup.removeControl(controlName);
			}
		}
	}

	//#endregion
}
