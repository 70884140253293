import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ApplicationUsersState, APPLICATION_USERS_TAG } from './application-users.reducer';

const selectApplicationUsersState = createFeatureSelector<ApplicationUsersState>(APPLICATION_USERS_TAG);

export const selectData = createSelector(selectApplicationUsersState, (state: ApplicationUsersState) => state.data);
export const selectPages = createSelector(selectApplicationUsersState, (state: ApplicationUsersState) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectApplicationUsersState, (state: ApplicationUsersState) => state.gridSearch);
export const selectStructureUUID = createSelector(selectApplicationUsersState, (state: ApplicationUsersState) => state.structureUUID);
export const selectStatus = createSelector(selectApplicationUsersState, (state: ApplicationUsersState) => state.status);
export const selectSort = createSelector(selectApplicationUsersState, (state: ApplicationUsersState) => state.sort);
export const selectCachedPages = createSelector(selectApplicationUsersState, (state: ApplicationUsersState) => state.cachedPages);
export const selectUserStructures = createSelector(selectApplicationUsersState, (state: ApplicationUsersState) => state.userStructures);
export const selectUserParts = createSelector(selectApplicationUsersState, (state: ApplicationUsersState) => state.parts);

//userCountPerStructureMap non viene più usato. Per il momento commento
// export const selectUserCountPerStructureMap = createSelector(
// 	selectApplicationUsersState,
// 	(state: ApplicationUsersState) => state.userCountPerStructureMap
// );

// export const selectStructuresAndCount = createSelector(
// 	selectUserCountPerStructureMap,
// 	selectUserStructures,
// 	(userCountPerStructureMap, userStructures) => {
// 		return userCountPerStructureMap.map((map) => {
// 			return {
// 				count: map.count,
// 				structure: userStructures.find((us) => us.uuid === map.structureUUID),
// 			};
// 		});
// 	}
// );
