import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { MatSortModule, Sort } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import {
	CheckboxComponent,
	DataleanTableColumlGeneric,
	DataleanTableColumn,
	DataleanTableColumnWithTemplate,
	ObjectValuesType,
	Page,
	PageChangeEvent,
	TableRow,
} from 'addiction-components';
import { BehaviorSubject } from 'rxjs';
import { PaginatorComponent } from '../paginator/paginator.component';

type SelectableItem<T> = { [key: string]: T & { checked?: boolean; timestamp?: number } };

@Component({
	selector: 'datalean-table',
	templateUrl: './datalean-table.component.html',
	styleUrls: ['./datalean-table.component.scss'],
	standalone: true,
	imports: [CommonModule, CdkTableModule, TranslateModule, PaginatorComponent, CheckboxComponent, CdkAccordionModule, MatSortModule],
})
export class DataleanTableComponent<T extends object> implements OnChanges {
	@Input({ required: true }) dataSource?:
		| null
		| Array<T>
		| {
				items: Array<Array<T>>;
				pages?: number[];
				totalItems?: number;
		  };

	@Input() columns: Array<DataleanTableColumn<T>> = [];
	@Input() templates: Array<TemplateRef<unknown>> = [];
	@Input() header?: {
		template: TemplateRef<unknown>;
		context?: unknown;
	};
	@Input() headerTemplate?: TemplateRef<unknown>;
	@Input() noItemsTemplate?: TemplateRef<unknown>;
	@Input() pageSize: number = 15;
	@Input() page?: Page | null;
	@Input() checkable?: boolean = false;
	@Input() loading?: boolean = false;
	@Input() maxCheckable?: number = 0;
	@Input() checkableSettings?: {
		checkable: boolean;
		maxCheckable?: number;
	} = {
		checkable: false,
		maxCheckable: 0, // 0 = no limit
	};
	@Input() selected?: Array<T> | null = [];
	@Input() accordion: boolean = false;
	@Input() accordionExpandedTemplate?: TemplateRef<unknown>;
	@Input() accordionExpandedList: Record<string, boolean> = {};
	@Input() accordionExpandedListKey = 'uuid';
	@Input() trClass: string = '';
	@Input() sortable: boolean = false;
	@Input() hidePaginatorIfNotNeeded: boolean = false;
	@Input() indeterminate: boolean = false;
	@Input() isRelatedEntities?: boolean = false;
	@Input() selectAll?: boolean = false;

	dSource = new BehaviorSubject<Array<T>>([]);
	displayedColumns$ = new BehaviorSubject<string[]>([]);

	columns$ = new BehaviorSubject([] as Array<DataleanTableColumlGeneric<T>>);

	curPage: number = 1;
	rowCount: number = 0;

	@Output() pageChange = new EventEmitter<PageChangeEvent>();
	@Output() selectedChange = new EventEmitter<Array<TableRow>>();
	@Output() rowClick = new EventEmitter<T>();
	@Output() sortChange = new EventEmitter<Sort>();
	@Output() checkboxHeaderSelected = new EventEmitter<boolean>();

	private _selectedItems: SelectableItem<T> = {};

	constructor() {}

	/**
	 * Qui dentro ci mettiamo i possibili cambiamenti che possono avvenire negli input
	 * mi raccomando l'ordine conta, se metti prima i columns e poi il checkableSettings
	 * non funziona perchè checkableSettings non è ancora stato inizializzato
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if ('checkableSettings' in changes) {
			if (changes['checkableSettings'].currentValue) {
				this.checkable = changes['checkableSettings'].currentValue.checkable;
				this.maxCheckable = changes['checkableSettings'].currentValue.maxCheckable ?? 0;
			}
		}

		if ('maxCheckable' in changes) {
			this.maxCheckable = changes['maxCheckable'].currentValue;
			this.checkable = true;
		}

		if ('columns' in changes) {
			// console.log('changes', changes);
			const columns = [] as Array<DataleanTableColumlGeneric<T>>;

			if (this.checkable) {
				columns.push({
					columnDef: 'check',
					header: '',
					cell: () => '',
				});
			}

			for (const column of changes['columns'].currentValue) {
				let columnConfig: DataleanTableColumlGeneric<T> = {
					columnDef: column.columnDef,
					header: column.header,
					cell: column.cell,
					tdClass: column.tdClass,
					thClass: column.thClass,
					template: column.template
				};

				if (typeof column.template === 'number') {
					columnConfig = { ...columnConfig, template: this.templates[column.template] };
				}

				columns.push(columnConfig);
			}

			// console.log('columns', columns);
			this.columns$.next(columns);
			this.displayedColumns$.next(columns.map((c) => c.columnDef));
		}

		if ('page' in changes) {
			if (changes['page'].currentValue) {
				// console.log('changes', changes['page'].currentValue);
				this.pageSize = changes['page'].currentValue.pageSize;
				this.curPage = changes['page'].currentValue.pageNumber + 1;
				this.rowCount = changes['page'].currentValue.totalElements;

				if (this.dataSource) {
					this.handleDataSource(this.dataSource);
				}
			}
		}

		if ('selected' in changes) {
			if (changes['selected'].currentValue) {
				this.selected = changes['selected'].currentValue;
				// console.log('selected', this.selected);
				this._selectedItems =
					this.selected?.reduce(
						(acc, value) => {
							const uuid: string | null = 'uuid' in value ? (value['uuid'] as string) : 'id' in value ? (value['id'] as string) : null;
							if (uuid) {
								acc[uuid] = { ...value, checked: true };
							}
							return acc;
						},
						{} as {
							[key: string]: T & {
								checked?: boolean | undefined;
							};
						}
					) ?? {};
			}
		}

		if ('loading' in changes) {
			// console.log('loading', changes['loading'].currentValue);
			this.loading = changes['loading'].currentValue;
			if (this.loading) {
				this.handleDataSource([this.displayedColumns$.value.reduce((acc, key) => ({ ...acc, [key]: true }), {} as T)]);
			}
		}

		if ('dataSource' in changes) {
			const data = changes['dataSource'].currentValue;
			if (data) {
				this.handleDataSource(data);
			}
		}
	}

	/**
	 * Questo metodo si occupa di gestire i dati che arrivano dal dataSource
	 * serve per gestire l'input sia come array che come observable
	 */
	handleDataSource(
		data:
			| Array<T>
			| {
					items: Array<Array<T>>;
					pages?: number[];
					totalItems?: number;
			  }
	) {
		// console.log('data', data);
		if (data instanceof Array) {
			// console.log('data', data);
			this.curPage = this.page?.pageNumber ? this.page.pageNumber + 1 : 1;
			const firstElementPageIndex = (this.curPage - 1) * this.pageSize;
			this.dSource.next(data.slice(firstElementPageIndex, firstElementPageIndex + this.pageSize));

			this.rowCount = data.length;
		}
		if (data instanceof Object) {
			if ('pages' in data && data.pages instanceof Array) {
				// console.log('data.pages', data.pages);
				this.curPage = (this.page?.pageNumber ?? data.pages[0]) + 1;
			}
			if ('items' in data && data.items instanceof Array) {
				// console.log('data.items', data.items);
				this.dSource.next(data.items[this.curPage - 1]);
			}
			if ('totalItems' in data) {
				// console.log('data.totalItems', data.totalItems);
				this.rowCount = data.totalItems ?? 0;
			}
		}
	}

	onPageChange(page: number): void {
		this.pageChange.next({
			offset: page,
			count: this.rowCount,
			limit: this.pageSize,
			pageSize: this.pageSize,
		});
	}

	hasTemplate(column: DataleanTableColumlGeneric<T>): column is DataleanTableColumnWithTemplate<T> {
		return 'template' in column;
	}

	/**
	 * TODO - aggiungere la logica di selezione multipla
	 */
	isRowChecked(column: DataleanTableColumlGeneric<T>, element: T) {
		const uuid: string | null = 'uuid' in element ? (element['uuid'] as string) : 'id' in element ? (element['id'] as string) : null;

		if (!uuid) return false;

		return this._selectedItems[uuid]?.checked ?? false;
	}

	/**
	 * Gestione di cosa è stato checkato e cosa no
	 * Può essere usato per gestire la selezione multipla
	 * TODO - controllare implementazione con il gcs
	 */
	checkRow(element: T, checked: boolean) {
		// console.log('checkRow', element, checked);
		const uuid: string | null = 'uuid' in element ? (element['uuid'] as string) : 'id' in element ? (element['id'] as string) : null;
		if (uuid) {
			this._selectedItems[uuid] = { ...element, checked, timestamp: Date.now() };
			let selected = Object.values(this._selectedItems).reduce(
				(acc, value) => (value.checked ? [...acc, { ...value }] : acc),
				new Array<ObjectValuesType<SelectableItem<T>>>()
			);
			if (this.maxCheckable && selected.length > this.maxCheckable) {
				selected = selected.sort((a, b) => (a.timestamp ?? 0) - (b.timestamp ?? 0));

				const toRemove = selected.length - this.maxCheckable;
				// console.log('toRemove', toRemove);
				const removed = selected.splice(0, toRemove);
				for (const item of removed) {
					item.checked = false;
					const uuid: string | null = 'uuid' in item ? (item['uuid'] as string) : 'id' in item ? (item['id'] as string) : null;
					if (uuid) {
						this._selectedItems[uuid] = item;
					}
				}
			}
			this.selectedChange.next(selected);
		}
	}

	public sortData(sort: Sort): void {
		this.sortChange.emit(sort);
	}

	public onCheckboxHeaderClick(checked: boolean): void {
		this.checkboxHeaderSelected.emit(checked);
	}
}
