import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuModule, MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'addiction-grid-options',
	templateUrl: './grid-options.component.html',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, MatMenuModule, TranslateModule],
})
export class GridOptionsComponent {
	@Input({ required: true }) options: Option[] = [];
	@Output() optionClick: EventEmitter<Option> = new EventEmitter();
	@Input() yPosition: MenuPositionY = 'below';
	@Input() xPosition: MenuPositionX = 'before';
  @Input() showOptionPoint: boolean = true;
	constructor() {}

	onOptionClick(option: Option) {
		this.optionClick.emit(option);
	}
}

export interface Option {
	id: string;
	label: string;
}
