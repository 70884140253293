import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
	private history: string[] = [];

	constructor(
		private router: Router,
		private location: Location,
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.history.push(event.urlAfterRedirects);
			}
		});
	}

	back(): void {
		if (this.history.length > 1) {
			this.history.pop();
			this.location.back();
		} else if (this.history.length === 1 && this.history[0] === '/collections') {
			//se c'è solo un elemento e sono nelle collections voglio una navigazione precisa in media library
			this.router.navigateByUrl('media-library');
		} else {
			this.router.navigateByUrl('/');
		}
	}

	goToNotFoundPage() {
		this.router.navigate(['not-found']);
	}
}
