import { Injectable } from '@angular/core';
import { DataleanBaseApiService, Parts } from 'addiction-components';
import { environment } from 'src/environments/environment';
import { DataleanUser } from '../models/base-user.interface';
import { SpecialHttpHeaderParams } from '../models/special-header-params.enum';

@Injectable({ providedIn: 'root' })
export class UserService {
	constructor(private baseApi: DataleanBaseApiService) {}

	getByUUID(uuid: string, options?: Partial<{ redirectIfNotFound: boolean }>) {
		const headerParams: Record<string, string | string[]> = {};

		if (options?.redirectIfNotFound) {
			headerParams[SpecialHttpHeaderParams.REDIRECT_IF_NOT_FOUND_HEADER_KEY] = 'true';
		}
		return this.baseApi.getOne<DataleanUser>([environment.usersUrl], uuid, [Parts.ROLES], undefined, headerParams);
	}

	updateUserFields(userUuid: string, body: Record<string, unknown>) {
		return this.baseApi.patchOne<DataleanUser>(environment.usersUrl, userUuid, body);
	}
}
