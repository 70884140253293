import { Directive, Output, EventEmitter, HostBinding, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[appDnd]',
	exportAs: 'appDnd',
	standalone: true,
})
export class DndDirective {
	@HostBinding('class.fileover') public fileOver: boolean = false;
	@HostBinding('class.dnd-container') isDndContainer = true;
	@Output() fileDropped = new EventEmitter<any>();
	@Input() accept: string | undefined | null;
	@Input() limit: number | undefined;

	constructor(private el: ElementRef) {}

	@HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();
		const temp = evt.dataTransfer;

		if (!this.fileOver && temp?.types?.includes('Files')) {
			this.fileOver = true;
		}
	}

	@HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();

		if (this.fileOver) {
			if ((evt.target as HTMLElement).classList.contains('dnd-container')) {
				this.fileOver = false;
			}
		}
		this.fileOver = false;
	}

	@HostListener('drop', ['$event']) public ondrop(evt: {
		preventDefault: () => void;
		stopPropagation: () => void;
		dataTransfer: { files: any };
		target: any;
	}) {
		evt.preventDefault();
		evt.stopPropagation();

		this.fileOver = false;
		//rimuovo le cartelle dalla lista
		const files: File[] = [...evt.dataTransfer.files].filter((f: File) => !!f.size).slice(0, this.limit);

		let foundElement = false;

		const siblingArray = [];
		//ricerca tra i nodi fratelli per verificare che il drop sia avvenuto nel posto giusto
		let node = this.el.nativeElement;
		while (node && !foundElement) {
			if (node.nodeType === Node.ELEMENT_NODE) siblingArray.push(node);
			if (node.contains(evt.target) || node == evt.target) {
				foundElement = true;
			}
			node = node.nextElementSibling || node.nextSibling;
		}

		if (files.length > 0 && foundElement) {
			const acceptedFiles: File[] = !this.accept ? files : this.filterFilesByAccept(files, this.accept.split(','));
			this.fileDropped.emit(acceptedFiles);
		}
	}

	private filterFilesByAccept(files: File[], acceptTypes: string[]): File[] {
		return files.filter((file) => {
			return acceptTypes.some((acceptType) => {
				if (acceptType.endsWith('/*')) {
					const baseType = acceptType.split('/')[0];
					return file.type.startsWith(baseType + '/');
				}
				// Confronto esatto per tipi MIME specifici
				return file.type === acceptType;
			});
		});
	}
}
