<!-- HEADER SOLO PER COLLECTION -->
<div class="header-container">
	<div class="left-container">
		<button class="back-button" (click)="back.emit()"></button>
		<addiction-search [value]="service.searchValue()" (search)="service.setSearchValue($event)"> </addiction-search>
	</div>
</div>
<!-- FINE HEADER COLLECTION -->

<div class="main-container">
	<div class="page-content">
		<ng-container *ngIf="service.gridData() as data">
			<addiction-grid
				[cardTemplate]="cardTemplate"
				[headerTemplate]="header"
				[pageSize]="pageSize"
				[data]="data"
				(pageChanges)="service.setPages($event)"
				(cardClick)="selectedCollections.emit($event)"
				[noItemsTemplate]="noItemsTemplate">
			</addiction-grid>
		</ng-container>
	</div>
</div>

<ng-template #cardTemplate let-item="item">
	<div class="card collections" *ngIf="item && service.status() !== STATE_STATUS.LOADING">
		<div class="text-container">
			<span class="title">{{ item.name }}</span>
			<span class="count">{{ item.assetsCount }} {{ item.assetsRef.length }}</span>
		</div>
	</div>

	<ng-container *ngIf="!item || service.status() === STATE_STATUS.LOADING">
		<div class="card loading">
			<div class="image-container"></div>
		</div>
	</ng-container>
</ng-template>

<ng-template #header>
	<div class="header-grid-container">
		<div class="header-grid">
			<div class="left-container">
				<div class="title">
					<h1>{{ 'COLLECTIONS.COLLECTIONS' | translate }}</h1>
				</div>
			</div>
			<div class="right-container">
				<div class="elements" *ngIf="service.gridData() as data">
					<span class="number">{{ data.totalItems }}</span>
					<span class="text">{{ 'COLLECTIONS.ELEMENTS' | translate }}</span>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #noItemsTemplate>
	<div class="no-results-container" *ngIf="service.status() !== STATE_STATUS.LOADING">
		<ng-lottie [options]="{ path: './assets/lottie/icn-dam-nocontent.json' }"></ng-lottie>
		<div class="title">
			{{ 'GRID.NO_RESULTS_TITLE' | translate }}
		</div>
		<div class="text">
			{{ 'GRID.NO_RESULTS_TEXT' | translate }}
		</div>
	</div>
</ng-template>
