import { createAction, props } from '@ngrx/store';
import { Structure, StructureType } from 'addiction-components';
import { STATE_STATUS } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { StructureFieldEditor } from '../../models/structure-editor/structure-field-editor';

export const setStructures = createAction('[Structures] Set Force Refresh', props<{ structureType: StructureType }>());
export const setStructuresSuccess = createAction(
	'[Structures] Set Datalean Structures Success',
	props<{ data: { totalStructuresCount: number; pages: number[]; structures: Structure[][] } }>(),
);
export const setStructuresError = createAction('[Structures] Set Datalean Structures Error', props<{ error: HttpErrorResponse }>());
export const setDeleteStructures = createAction('[Structures] Set Delete Datalean Structures', props<{ structures: Structure }>());
export const setDeleteStructuresSuccess = createAction('[Structures] Set Delete Datalean Structures Success');
export const setDeleteStructuresError = createAction('[Structures] Set Delete Datalean Structures Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Structures] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Structures] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Structures] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Structures] Set search', props<{ search: string | null }>());
export const structureTypeChanged = createAction('[Structures] Structure Type Changed', props<{ structureType: StructureType }>());
export const setCurrentStructureField = createAction(
	'[Structures] Set Current Structure Field',
	props<{ structureField: StructureFieldEditor | undefined }>(),
);
export const setPath = createAction('[Structures] Set Current Path And Structure Field', props<{ uuid: string; path: string }>());
export const setCachedPages = createAction('[Structures] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Structures] reset cache');
export const setIsDragging = createAction('[Structures] Set is dragging', props<{ isDragging: boolean }>());
export const setDraggingUUID = createAction('[Structures] Set dragging UUID', props<{ draggingUUID: string | undefined }>());
export const setContainersCoordinates = createAction(
	'[Structures] Set Container Coordinates',
	props<{ containerUUID: string; containerY: number }>(),
);
export const setContainerUUIDToExpand = createAction('[Structures] Set Container UUID To Expand', props<{ containerUUID: string | undefined }>());
export const setLastIndex = createAction('[Structures] Set Last Index', props<{ lastIndex: number }>());
export const setIsSubStructure = createAction('[Structures] Set is sub structure', props<{ isSubStructure: boolean }>());
export const setDesktopRatioAndUuid = createAction(
	'[Structures] Set Desktop Ratio',
	props<{ desktopRatioAndUuid: { desktopRatio: number; uuid: string } }>(),
);
export const setCollapsedContainers = createAction(
	'[Structures] Set collapsed containers',
	props<{ containerUUID: string | undefined; collapsed: boolean }>(),
);
export const resetCollapsedContainers = createAction('[Structures] Reset collapsed containers');
