import { Injectable, computed, signal } from '@angular/core';
import { PaginationInfo, Parts, DataleanBaseApiService, Collection, SearchInfo } from 'addiction-components';
import { environment } from 'src/environments/environment';
import { Observable, forkJoin, switchMap } from 'rxjs';
import { FilterParams } from 'src/app/shared/models/filterParams';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';

@Injectable()
export class CollectionsService {
	private _gridData = signal<{ items: Collection[][]; pages: number[]; totalItems: number }>({ items: [], pages: [0], totalItems: -1 });
	private _searchValue = signal<string | null>(null);
	private _pages = signal<number[]>([0]);
	private _status = signal<STATE_STATUS>(STATE_STATUS.LOADING);

	status = computed(() => this._status());
	gridData = computed(() => this._gridData());
	searchValue = computed(() => this._searchValue());
	pages = computed(() => this._pages());

	fetchParams = computed(() => {
		const params: {
			searchInfo?: SearchInfo;
			pages: number[];
		} = {
			pages: [0],
		};

		const searchValue = this.searchValue();
		const pages = this.pages();

		if (searchValue) {
			params.searchInfo = new SearchInfo('name', searchValue, 'searchFields', 'q');
		}
		if (pages?.length) {
			params.pages = pages;
		}
		return params;
	});

	fetchCollections$ = toObservable(this.fetchParams).pipe(
		switchMap(({ searchInfo, pages }) => {
			this.setStatus(STATE_STATUS.LOADING);
			const calls: Observable<{ result: Collection[] | null; paginationInfo?: PaginationInfo | undefined }>[] = [];
			for (const page of pages) {
				calls.push(
					this.dataleanApi.getEntitiesWithPaginationData<Collection>(
						environment.collectionsUrl,
						undefined,
						[],
						searchInfo,
						new PaginationInfo(environment.pageSize, page)
					)
				);
			}
			return forkJoin(calls);
		})
	);

	constructor(private dataleanApi: DataleanBaseApiService) {
		this.fetchCollections$.pipe(takeUntilDestroyed()).subscribe((data) => {
			const startingResult: { pages: number[]; assets: Collection[][]; totalAssetCount: number } = {
				pages: [],
				assets: [],
				totalAssetCount: 0,
			};
			const result = data.reduce((acc, item) => {
				if (item.paginationInfo) {
					acc.pages.push(item.paginationInfo.numberOfPage);
					acc.totalAssetCount = item.paginationInfo.totalNumberOfElements;
					if (item.result) {
						acc.assets[item.paginationInfo.numberOfPage] = item.result;
					}
				}
				return acc;
			}, startingResult);
			this.setStatus(STATE_STATUS.READY);
			this.setGridData(result.assets, result.pages, result.totalAssetCount);
		});
	}

	private setStatus(status: STATE_STATUS) {
		this._status.set(status);
	}
	setGridData(collections: Collection[][], pages: number[], totalItems: number) {
		this._gridData.set({ items: collections, pages, totalItems });
	}
	setSearchValue(searchValue: string | null) {
		this._searchValue.set(searchValue);
	}
	setPages(pages: number[]) {
		this._pages.set(pages);
	}

	fetchCollections(pages: number[], gridSearch?: string): Observable<{ result: Collection[] | null; paginationInfo?: PaginationInfo }[]> {
		const obs: Observable<{ result: Collection[] | null; paginationInfo?: PaginationInfo }>[] = [];
		for (const page of pages) {
			const pagination = new PaginationInfo(25, page);
			const params: FilterParams = {};
			if (gridSearch) {
				params.q = gridSearch;
				params.searchFields = 'name';
			}
			obs.push(
				this.dataleanApi.getEntitiesWithPaginationData<Collection>(
					environment.collectionsUrl,
					params,
					[Parts.EMPTY],
					undefined,
					pagination,
					undefined
				)
			);
		}
		return forkJoin(obs);
	}
}
