import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { Store, StoreModule } from '@ngrx/store';
import { ConfigurationValue } from 'addiction-components';
import { combineLatest, distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { NavLink } from '../../../shared/models';
import { SharedModule } from '../../../shared/shared.module';
import { NavigationSelector, UserSelector } from '../../state/app.selectors';

@Component({
	selector: 'datalean-main-navbar',
	templateUrl: './main-navbar.component.html',
	styleUrls: ['./main-navbar.component.scss'],
	standalone: true,
	imports: [CommonModule, StoreModule, CdkAccordionModule, MatExpansionModule, SharedModule, RouterModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavbarComponent {
	@ViewChild(MatAccordion) accordion!: MatAccordion;

	protected configurationService = inject(ConfigurationService);
	private store = inject(Store);
	private specialPaths = ['edit', 'new', 'clone', 'detail'];

	public hasVersioning$ = this.configurationService.isReady.pipe(
		filter((isReady) => isReady),
		map(() => this.configurationService.getConfigValue('hasVersioning')),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	links$ = combineLatest([
		this.store.select(UserSelector.selectUserData).pipe(filter((data) => !!data)),
		this.configurationService.configChanges$, // Ascolto i cambiamenti nelle configurazioni
	]).pipe(
		switchMap(() =>
			this.hasVersioning$.pipe(
				// Quando cambia `hasVersioning$`, rifacciamo la chiamata per i navLinks
				switchMap((hasVersioning) =>
					this.store.select(NavigationSelector.selectNavLinks).pipe(
						map((navLinks) => ({
							originalNavLinks: navLinks, // Manteniamo la lista originale
							hasVersioning,
						})),
					),
				),
			),
		),
		distinctUntilChanged((prev, curr) => {
			// Controllo se i navLinks o hasVersioning sono cambiati
			const areArraysEqual =
				prev.originalNavLinks.length === curr.originalNavLinks.length &&
				prev.originalNavLinks.every(
					(link, index) =>
						link.label === curr.originalNavLinks[index].label && link.children?.length === curr.originalNavLinks[index].children?.length,
				);

			return areArraysEqual && prev.hasVersioning === curr.hasVersioning;
		}),
		map(({ originalNavLinks, hasVersioning }) => {
			// Filtro dinamicamente i navLinks senza alterare la lista originale
			return this.filterVersioningNavlink(hasVersioning, structuredClone(originalNavLinks));
		}),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	public activePath$ = this.store.select(NavigationSelector.selectActivePath).pipe(shareReplay({ bufferSize: 1, refCount: true }));

	/**
	 * Controlla se uno specifico NavLink è selezionato in base al percorso attivo.
	 * @param navLink Il NavLink da controllare per la selezione.
	 * @param activePath Il percorso attivo corrente da confrontare.
	 * @Ritorno true se il NavLink è selezionato, altrimenti false.
	 */
	public checkIfNavLinkSelected(navLink: NavLink, activePath?: string | null): boolean {
		if (!activePath) return false;

		if (!navLink.children) {
			for (const path of this.specialPaths) {
				if (activePath.includes(path)) return activePath.startsWith(`${navLink.path}/${path}`);
			}
			return navLink.path === activePath;
		}

		return !!navLink.children.find((childLink) => {
			for (const path of this.specialPaths) {
				if (activePath.includes(path)) return activePath.startsWith(`${childLink.path ?? ''}/${path}`);
			}
			return childLink.path === activePath;
		});
	}

	/**
	 * Funzione per settare l'active sui navLink in base all'active path.
	 * Se l'active path contiene edit o new va aggiunto anche al button path per gestire l'apertura dei record in modifica
	 * @param buttonPath il path contenuto nel navLink
	 * @param activePath l'active path corrente
	 * @returns true se l'active path è uguale al path del nav link. Se active path contiene 'edit' o 'new' viene tornato true
	 * se l'activePath contiene il path del nav link + '/edit' o '/new'.
	 * Viene fatto il contains perchè l'active path in edit e new contiene l'uuid e quindi non può essere fatta un comparazione diretta.
	 */
	public checkIfButtonActive(buttonPath: string | undefined, activePath: string | null | undefined) {
		if (!buttonPath || !activePath) return false;

		/**
		 * Questo è un caso limite che va gestito singolarmente.
		 * Dato che il navLink bozze in edit non apre path product/draft/edit ma viene usato un query param
		 * il path è sempre products/edit.
		 * Di conseguenza è necessario gestirlo con un if specifico.
		 */
		if (activePath.includes('isFromWorkFlowTasks=true')) return buttonPath === '/products';
		if (activePath.includes('status=DRAFT')) return buttonPath === '/products/drafts';

		for (const path of this.specialPaths) {
			if (activePath.includes(path)) return activePath.startsWith(`${buttonPath}/${path}`);
		}

		return activePath === buttonPath;
	}

	trackBy(index: number, links: NavLink): string {
		// console.log('links', links);
		// console.log('index', index);
		return links.label;
	}

	/**
	 * Filtra i link di navigazione in base alla presenza del versioning
	 *
	 * @param hasVersioning - Il valore di configurazione che indica la presenza del versioning.
	 * @param navLinks - array opzionale di link di navigazione da filtrare.
	 * @Ritorno Un array di link di navigazione filtrati in base alle condizioni.
	 */
	public filterVersioningNavlink(hasVersioning: ConfigurationValue | null, navLinks?: NavLink[]): NavLink[] {
		if (!navLinks) return [];

		return navLinks.filter((navLink) => {
			if ((navLink.checkVersioning && !hasVersioning) || navLink.hidden) return null;

			if (navLink.children) {
				navLink.children = this.filterVersioningNavlink(hasVersioning, navLink.children);
			}

			return navLink;
		});
	}
}
