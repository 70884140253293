export interface FilterParams {
    assetType?: string;
    parentUUID?: string;
    featureValueList?: string;
    tags?: string;
    format?: string;
    createDate?: string;
    updateDate?: string;
    sortBy?: string;
    q?: string; //query fatta dal utente
    searchFields?: string; //quando utente fa la query passarlo con name:q
    isPublic?: boolean, // se settato a false, recuperi anche le collection private
    communityUUID?: string
}
